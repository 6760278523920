// should first resolve refs
export function traverseSchema(schema, key, rootSchema) {
    if (!schema) return
    let schemas = Array.isArray(schema) ? schema : [schema]
    let childSchemas = []
    for (let s of schemas) {
        if (s.$patch || s.$merge) {
            throw 's.$patch and s.$merge are not supported in the schema'
        }
        if (schema.type === 'array') {
            childSchemas.push(s.items)
        } else if (schema.type === 'object') {
            for (let candidate of [s, ...(s.anyOf || []), ...(s.allOf || []), ...(s.oneOf || [])]) {
                childSchemas.push((candidate.properties || {})[key])
            }
        }
    }

    childSchemas = childSchemas.filter(s => s)
    if (!childSchemas.length) return
    return childSchemas
}


export function infereSchema(value, key) {
    if (Array.isArray(value)) return { type: 'array' }

    return { type: typeof value }
}

export function castValue(value, schema) {

    switch (schema.type) {
        case 'number': return Number(value)
        default: return value
    }
}

import $RefParser from 'json-schema-ref-parser'


export async function resolveReferences(schema) {
    const r = await $RefParser.dereference(schema)
    console.log('dereferenced', r)
    return r
}