import notify from '../../notifications'
import { defaultToolSettings, SimpleToolSettings } from './settings'
import SOURCES from './sources'
import mountWidget from './widget'
import * as GDrive from '../../google/index'

import "../../style/style.css"
import { ConfigPreset, getPresetList } from './preset'
import { createValidation, prettifyErrors } from '../../json/schema-validation'

// or base_url in get params
const { searchParams } = new URL(document.URL)

let baseUrl = window.location.ancestorOrigins[0] || window.origin
if (searchParams.has('base_url'))
    baseUrl = searchParams.get('base_url') || ""
if (!baseUrl.endsWith('/'))
    baseUrl += "/"

console.log('base_url : ', baseUrl)
export function resolveUrl(url) {
    if (!url.startsWith('.'))
        return url
    // let relativeUrl = url.split('./').slice(1).join('./')
    url = baseUrl + url
    console.log('resolved url', url)
    return url
}
export default async function createSimpleTool(overrides: Partial<SimpleToolSettings>) {
    const settings = { ...defaultToolSettings, ...(arguments[0] || {}) }
    const toolName = settings.name
    const { setup, historyLength, widgetContainer, sources, schema, normalize, builtins } = settings

    let alive = true, validate
    let currentPreset: ConfigPreset | null = null
    const checkAlive = () => {
        if (!alive)
            throw `simple tool is not alive`
    }

    async function useConfig(preset: ConfigPreset) {
        if (!preset) {
            const presets = await getPresetList(null, settings)
            const preferred = searchParams.get('preferred')
            if (preferred) {
                preset = presets.filter(p => p.name === preferred)[0]
            }
            if (!preset)
                preset = presets[0]

        }

        const { raw, source, name } = preset || {}
        notify({ level: 'debug', message: preset ? `trying to use ${name} from ` + source : `using empty config`, theme: ['TOOL', 'SETUP', 'LAUNCH'] })
        const candidate = raw
        // notify({ message: 'using config from ' + source })
        if (!candidate && false)
            alive = false
        checkAlive()

        let normalized = candidate
        if (normalized) {
            if (normalize) {
                normalized = await normalize(candidate)
            }
            if (schema) {
                if (!validate)
                    validate = await createValidation(schema)
                console.log('checking', normalized, 'against', schema)
                if (!validate(normalized)) {
                    const errors = prettifyErrors(validate.errors)
                    const err = `Schema is invalid: ${errors[errors.length - 1]}`
                    notify({ message: err, detail: errors, level: 'error', theme: ['TOOL', 'SETUP', 'ERROR'] })
                    throw `Schema is invalid`
                }
            }
        } else {
            throw `No config found`
        }

        let state = {
            ...(preset || {}),
            original: normalized,
            current: normalized
        }
        const handleUpdate = async (newConfig) => {
            state.current = newConfig
            await mountWidget(state, settings, useConfig)
        }


        await setup(normalized, handleUpdate, source)
        current = normalized
        await mountWidget(preset, settings, useConfig)

    }
    const render = () => mountWidget(currentPreset, settings, useConfig)
    notify({ message: 'launching simple tool ' + toolName, level: 'debug', detail: settings })
    await render()
    if (sources.includes('gdrive'))
        await GDrive.init(render)

    // handle global drop ?

    let current
    for (let source of sources) {
        notify({ message: 'looking for config in ' + source, level: 'debug' })
        if (typeof source === 'string')
            source = { name: source, parameters: null }
        const { name, parameters } = source
        if (current || !name)
            continue;
        try {
            const load = SOURCES[name]
            if (!load)
                throw `Unknown source`
            const result = await load(parameters)
            if (result) {
                result.source = name
                await useConfig(result as ConfigPreset)
            }
        } catch (err) {
            notify({ level: 'debug', message: "failed to load config from " + name, detail: err.toString() })
        }
    }
    if (!current)
        await useConfig(null)

    return (config, name = 'default-name') => useConfig({ raw: config, source: 'custom', name })

}