import { css } from 'lit'

export default css`
:host {
    color: white;
    background-color: antiquewhite;
    /* height: 300px; */
    flex: 1;
    overflow: hidden;
    position: relative;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: stretch;

}

.btn {
    border: 2px solid rgba(150, 150, 150, 1);
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn.soft{
    border: none;
}


.btn:hover {
    opacity: 0.8;
}


.inactive {
    opacity: 0.35;
}

.active {
    opacity: 1;
}
#rightbar {
    max-height: 100vh;
    width: 40vw;
    max-width: 500px;
}

#topbar .widget svg{
    max-width: 30px;
    max-height: 30px;
}

#topbar .widget{
    display: flex;
    align-items: center;
    justify-content: center;
}
#topbar .widget .active{
    color: darkgreen;
}

.ok{
    color: darkgreen;
}
.ko{
    color: darkred;
}

#botbar{
    color: white;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    pointer-events: none;
    width: 100%;
}
#legend{
    margin: 15px 10px;
}

#botbar .btn{
    pointer-events: all;
}

.widget > div{
    height: 24px;
    min-width: 24px;
}

.library-items{
}
.library-item{
    border-bottom: 1px solid white;
}

h2{
    margin-bottom: 5px;
    text-align: center;
    color: lightyellow;
    background-color: darkgreen;
}

.link{
    margin: 5px;
    font-size:1.2rem;
    align-items: flex-end;
}
.link input,.link select,.link option{
    flex: 1;
    width:25%;

    background-color: black;
    color: white;
    
    font-size: inherit;
    border: none;
    border-bottom: 1px solid grey;
    padding: 0;
}
`