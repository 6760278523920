export default function showHelp() {
    const boundary = '----------'
    const helpText =
        `${boundary}
HELP

right click : open menu 
x           : delete selected components
${boundary}`
    console.log(helpText)
}
