import { html, LitElement, css } from "lit-element";

class DropZoneElement extends LitElement {
  constructor() {
    super();
  }

  firstUpdated() {}
  render() {
    return html` <div
      class="root"
      @dragover="${(e) => this.handleDragOver(e)}"
      @dragenter="${(e) => this.handleDragEnter(e)}"
      @dragleave="${(e) => this.handleDragLeave(e)}"
      @drop="${(e) => this.handleDrop(e)}"
    >
      <div class="effect ${this.active ? "active" : ""}"></div>
      <slot></slot>
    </div>`;
  }

  static get properties() {
    return {
      active: { attribute: false },
    };
  }

  handleDragEnter(e) {
    e.preventDefault();
    this.active = true;
  }
  handleDragLeave(e) {
    e.preventDefault();
    this.active = false;
  }
  handleDragOver(e) {
    e.preventDefault();
  }

  handleDrop(e) {
    console.log("drop", e);
    e.preventDefault();
    this.active = false;
  }
  static get styles() {
    return css`
      .root {
        width: 100%;
        height: 100%;
        position: relative;
      }
      :host {
        flex: 1;
      }
      .effect {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;
        pointer-events: none;
        background-color: black;
        opacity: 0;
      }
      .effect.active {
        opacity: 0.4;
      }
    `;
  }
}
const NAME = "drop-zone";
if (!customElements.get(NAME)) customElements.define(NAME, DropZoneElement);
