import './style.css'
import { Action } from '../manipulator/action';
import { render, html } from 'lit'
import { get } from 'object-path-immutable';


const MENU_OFFSET = 20;




function renderTitle(ctx) {
    const { title, path } = ctx
    if (title)
        return html`<pre class="menu-title">${Array.isArray(title) ? title.join("\n") : title}</pre>`
}
function renderContext(ctx, update) {
    const { path } = ctx
    function handleClick(part) {
        const idx = path.indexOf(part)
        const newPath = path.slice(0, idx)
        update({
            ...ctx,
            path: newPath
        })
    }
    return html`<div class="menu-context">${path.map((p) => html`<span class="clickable" @click=${() => handleClick(p)} ><span> > </span><span>${p}</span></span>`)} </div>`
}

function renderActions(ctx, update) {
    const { title,
        actions,
        data,
        path } = ctx
    let currentActions = actions
    for (let p of path) {
        const actionGroup = currentActions.filter(a => a.name === p)[0]
        if (actionGroup && actionGroup.group)
            currentActions = actionGroup.group || []
        else
            throw `unable to find action group ${p}`
    }

    return html`<div class="action-list">${currentActions.map(action => {
        const handleClick = (evt) => {
            if (action.perform) {
                const p = action.perform(data)

                if (p && p.then)
                    p.then((result) => {
                        console.log('action succeeded ', result || '')
                    }).catch(err => {
                        console.log('action failed')
                        console.log(err.toString())
                    });
                else {
                    console.log('action succeeded synchronously', p || '')
                }
                closeMenus();
            } else if (action.group) {
                const newContext = {
                    ...ctx,
                    path: [...ctx.path, action.name]
                }
                update(newContext)
            }
        }
        return html`<div class="" @click="${handleClick}">${action.text}</div>`
    })}</div>`
}




function renderContextMenu(ctx, menu) {
    // if (title) {
    //     let titleElt = document.createElement('pre')
    //     titleElt.innerHTML = 
    //     titleElt.classList.add('menu-title')
    //     menu.appendChild(titleElt)
    // }


    // let actionList = document.createElement('div')
    // actionList.classList.add('action-list')
    // for (let action of actions) {
    //     addAction(actionList, action, actionData)
    // }
    // menu.appendChild(actionList)
    function update(newContext) {
        const { clientWidth, clientHeight } = menu
        // set width,height to enable transition : NOT WORKING
        menu.style.width = '' + clientWidth + 'px'
        menu.style.height = '' + clientHeight + 'px'
        requestAnimationFrame(() => {
            renderContextMenu(newContext, menu)
        })
    }
    menu.style.width = ''
    menu.style.height = ''
    render(html`
    ${renderContext(ctx, update)}
    ${renderTitle(ctx)}
    ${renderActions(ctx, update)}
    `, menu)
}
export default function createContextMenu(event, data, actions, title = "") {


    // TODO : validate data : uniq name per level 
    const ctx = {
        title,
        actions,
        event,
        data,
        path: []
    }

    const parent = document.body
    let menu = document.createElement('div')
    menu.classList.add('context-menu')

    let h = window.innerHeight;
    let w = window.innerWidth;
    let y = event.clientY
    let x = event.clientX
    if (x > w / 2) {
        menu.style.right = (w - event.clientX) - MENU_OFFSET + 'px';
    } else {
        menu.style.left = event.clientX - MENU_OFFSET + 'px';
    }
    if (y > h / 2) {
        menu.style.bottom = (h - event.clientY) - MENU_OFFSET + 'px';
    } else {
        menu.style.top = event.clientY - MENU_OFFSET + 'px';
    }


    renderContextMenu(ctx, menu)
    parent.appendChild(menu);
    menu.addEventListener('mouseleave', () => {
        menu.parentNode.removeChild(menu)
    })
}

export function closeMenus() {

}



