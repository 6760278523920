import { LitElement, html } from 'lit-element'
import Searcher from './search-engine'


class FrontSearcher extends LitElement {
    constructor() {
        super()
        this.searcher = new Searcher()
        this.rows = []
        this.value = []
        this.term = ""
    }

    static get properties() {
        return {
            rows: { type: Array },
            fields: { type: Array },
            suggest: { type: Boolean },
            value: { type: Array },
            term: { type: String, reflect: true }
        }
    }
    handleInput(e) {
        this.term = e.target.value
    }

    search(term) {
        let searchResult
        let t0 = Date.now()
        if (term) {
            searchResult = this.searcher.search(term)
        } else {
            searchResult = this.rows.map((doc, index) => ({ id: doc.id, index }))
        }
        const dt = Date.now() - t0
        // console.log('search in ' + dt + 'ms')
        this.value = searchResult
        const evt = new CustomEvent("search", { bubbles: true, detail: searchResult, })
        this.dispatchEvent(evt)
    }
    updated(oldUpdatedProps) {
        super.updated(oldUpdatedProps)
        let willSearch = false
        if (oldUpdatedProps.has('rows')) {
            this.searcher.useItems(this.rows.map((item, index) => ({ ...item, index })))
            willSearch = true
        }
        if (oldUpdatedProps.has('fields')) {
            this.searcher.configure({ fields: this.fields })
            willSearch = true
        }
        if (willSearch)
            this.search(this.term)

    }
    attributeChangedCallback(name, oldValue, value) {
        super.attributeChangedCallback(...arguments)
        if (name === 'term') {
            this.search(this.term)
        }
    }

    firstUpdated() {
        console.log('frint searcher first updated')
        this.shadowRoot.querySelector('input').focus()
        this.search(this.term)
    }

    render() {
        return html`
            <style>
                input{
                    width: 100%;
                    font-size: 1.5rem;
                    padding: 5px;
                    margin: 0;
                    border: none;
                }
                #root{
                    display: flex;
                    align-items: center;
                }
            </style>
<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>

            <div id="root">
                <input @input="${e => this.handleInput(e)}" type="text" placeholder="search ..." /> 
            </div>
            `
    }
}

customElements.define('front-searcher', FrontSearcher);
console.log('front-searcher element defined')