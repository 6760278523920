import MiniSearch from 'minisearch'


/*
  Futur : should allow numeric/date comparison, and,or,not
  cf doc : https://lucaong.github.io/minisearch/modules/_minisearch_.html
*/
const defaultConfig = {
    expandString: (s, i) => ({ name: s, id: s, index: i }),
    search: {
        // prefix: true,
        fuzzy: 0.1,
        // boost: {name: 2},
        // boost: {
        //     name: 2
        // },
        //tokenize: (string, field) => string.split('-') 
        //processTerm
    }
}



export default class Searcher {
    constructor() {
        this.configure()
    }


    configure(cfg = {}) {
        const search = Object.assign({}, defaultConfig.search || {}, cfg.search || {})
        this.config = { ...defaultConfig, ...cfg, search }

        const fields = cfg.fields || ['name', 'tags', 'summary', 'title', 'description', "mimeType", "category", "message"]
        this.ms = new MiniSearch({
            //tokenize: (string, field) => string.split('-')
            //extractField(doc, field){return doc.data[field]}
            //processTerm
            idField: 'index',
            fields,
            searchOptions: {
                combineWith: "AND",
                prefix: false,

            },
            extractField: (document, fieldName) => {
                switch (fieldName) {
                    case 'tags':
                        let tags = ''
                        const extractTags = (dict = {}) => {
                            for (let key in dict || {}) {
                                if (key.length <= 2 && key.startsWith('t')) // google drive unnamed tags : only the value matters
                                    tags += `${dict[key]} `
                                else if (!dict[key]) {
                                    tags += `${key} `
                                } else
                                    tags += `${key}=${dict[key]} `
                            }
                        }
                        extractTags(document.properties)
                        extractTags(document.appProperties)
                        return tags
                    default:
                        return document[fieldName]
                }
            },
            storeFields: ['id', 'name', 'index']
        })
        if (this.items)
            this.ms.addAll(this.items)

    }

    useItems(list = []) {
        // should infere searchable fields
        this.configure(this.config)
        list = list.map(item => typeof item === 'string' ? this.config.expandString(item) : item)
        this.ms.addAll(list)
        this.items = list
    }

    // should search terms in order
    search(term, cfg = {}) {
        const parts = term.split(' ').filter(p => p)
        let finalItems
        const typedPartFilter = p => p.includes(':')
        const comparedPartFilter = p => p.includes('<') || p.includes('>')
        for (let part of parts) {
            let items
            if (typedPartFilter(part)) {
                const [field, value] = part.split(':')
                let searchConfig = { ...this.config.search, ...cfg, fields: [field] }
                items = this.ms.search(value, searchConfig) //id,name,match,score,terms

            } else if (comparedPartFilter(part)) {
                throw 'comparison in search not implemented'
            } else {
                let searchConfig = { ...this.config.search, ...cfg }
                items = this.ms.search(part, searchConfig) //id,name,match,score,terms
            }
            const ids = items.map(doc => doc.id)
            if (finalItems) {
                finalItems = finalItems.filter(({ id }) => ids.indexOf(id) >= 0)
            } else {
                finalItems = items
                console.log(`got ${finalItems.length}/${this.items.length} items`)
            }
        }
        // const items = this.ms.autoSuggest(term, {filter: r=>r})
        return finalItems
    }
}