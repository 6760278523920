import { html, svg } from 'lit'
import { getLayers, toggleLayer } from './store'

import * as icons from './icons'
import { exportGraph, getNode } from './graph'


export const graphViewRoot = {
    name: 'view-root',
    render: function (state) {
        // const path = ['physics', 'mechanics', 'fluid dynamics', 'naca']
        let root = getNode(state.nodes, state.root)
        if (!root) return
        let path = []
        let id = state.root
        while (id && (path.length < 10)) {
            path = [root, ...path]
            root = getNode(state.nodes, root.parent) || {}
            id = root.id
        }

        return html`<div class="row">${[
            ...path.map((node, index) => html`${index > 0 ? html`<span> / </span>` : ''}<span class="soft btn" style="white-space: nowrap;" @click="${e => this.commit('set-view-root', { target: getNode(state.nodes, node.parent) })
                }">${node.name || node.id}</span>`)
        ]}</div>`
    }
}

import showHelp from './help.js'
export default [
    {
        name: 'show-help',
        render: (state, update, commit) => {
            return html`<div title="help" class="soft btn" @click="${showHelp}">${icons.question}</div>`
        }
    },
    {
        name: 'import-graph',
        render: (state, update, commit) => {
            return html`<div title="import graph" class="soft btn" @click="${e => commit('import-graph')}">${icons.importGraph}</div>`
        }
    },
    {
        name: 'reset-graph',
        render: (state, update, commit) => {
            return html`<div  title="reset graph" class="soft btn" @click="${e => commit('reset-graph')}">${icons.reset}</div>`
        }
    },
    // {
    //     name: 'import-schema',
    //     render: (state, update, commit) => {
    //         return html`<div class="soft btn" @click="${e => commit('import-schema')}">${icons.schema}</div>`
    //     }
    // },
    {
        name: 'export-graph',
        render: (state, update, commit) => {
            return html`<div title="export graph" class="soft btn" @click="${e => commit('export-graph')}">${icons.exportGraph}</div>`
        }
    },

    {
        name: 'fit-schema',
        render: (state, update, commit) => {
            return html`<div title="compute layout" class="soft btn" @click="${e => commit('fit-graph')}">${icons.fit}</div>`
        }
    },
    {
        name: 'layers',
        render: (state, update, commit) => {
            const { layers } = state
            const allLayers = getLayers(state)

            return html`
            <div class="row" title="layers" style="margin: 0 15px;font-size: 0.7em;">
            
                <div style="width: 12px;height: 12px;" @click=${e => {
                    update({ layers: state.layers ? null : [] })
                }} class="btn">${icons.layers}</div>
            
                ${allLayers.map(layer => html`
                <div style="width: 12px;height: 12px;" @click=${e => {
                        update(toggleLayer(state, layer))
                    }} class="${'btn ' + ((!layers ||
                        layers.indexOf(layer) >= 0) ? 'active' : 'inactive')}">${layer}</div>
                `)}
            </div>`
        }
    },
    {
        name: 'pin-panel',
        render: (state, update, commit) => {
            return html`<div class="soft btn ${state.pinned ? 'active' : ''}" title="pinned: ${state.pinned || '-'}" @click="${e => update({ pinned: state.selection[0] })}">${icons.thumbstack}</div>`
        }
    },
    // {
    //     name: 'launch',
    //     render: (state, update, commit) => {
    //         return html`<div class="soft btn" @click="${e => commit('launch')}">${icons.rocket}</div>`
    //     }
    // },

]