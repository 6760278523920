import { LitElement, html, css, } from 'lit-element'
import { get, set } from 'object-path-immutable'
import { traverseSchema, infereSchema, castValue } from './schema-utils'



import Ajv from 'ajv'
import { dereference } from 'json-schema-ref-parser';
import { renderNumber } from './renderers';

const ajv = new Ajv({ allErrors: true, strictSchema: false });
ajv.addKeyword("input")

const customRenderers = {
    color(value, schema, update) {
        return html`<input type="color" value="${value}" @input=${e => update(e.target.value)} />`
    },
    range(value, schema, update) {
        let { min, max, step } = schema.input
        if (min === undefined) min = -1
        if (max === undefined) max = 1
        if (step === undefined) step = 0.1

        return html`<input type="range" min="${min}" max=${max} step="${step}" .value=${value} @input="${e => { update(e.target.value) }}" />${value}`

    }
}

class EditObject extends LitElement {
    constructor() {
        super()
        this.initial = {}
        this.schema = {}
        this.value = {}
        this.set = null
        this.path = []
        this.depth = 2
        this.loading = false
        this.mode = null

    }
    static get properties() {
        return {
            schema: { type: Object, reflect: true },
            initial: { type: Object },
            value: { type: Object, reflect: true },
            set: { type: Function },
            path: { type: Array },
            depth: { type: Number },
            loading: { type: Boolean },
            mode: { type: String }
        }
    }


    updated(changes) {
        const { lastUpdate } = this
        if (lastUpdate) {
            try {
                const inputElement = this.shadowRoot.getElementById(lastUpdate)
                if (inputElement)
                    inputElement.focus()
            } catch (err) {
                console.log('failed to focus', lastUpdate, err.toString())
            }
            // if(inputElement)
        }
        if (changes.has('initial')) {
            this.value = this.initial
        }
        if (changes.has('schema')) {
            if (!this.schema) {
                this.loading = false
            } else {
                this.loading = true
                console.log('new schema', this.schema)
                if (false) {
                    dereference(this.schema).then((s) => {
                        console.log('got dereferenced schema', this.schema, '->', s)
                        this.derefSchema = s
                    }).catch(err => {
                        console.log('failed to dereference schema', err.toString())
                    }).finally(() => {
                        this.loading = false
                    })
                } else {
                    this.derefSchema = this.schema
                    this.loading = false
                }
                /*

                */
            }

        }
        if (changes.has('value')) {
            this.stringValue = JSON.stringify(this.value, null, 2)
        }

    }
    sch() {
        return this.derefSchema || this.schema
    }



    render() {
        let currentJson = get(this.value, this.path)
        let currentSchema = this.getSchema(this.path)

        return html`
        <div class="root column">
            ${this.renderHead()}
            <section class="main">
                ${this.mode === 'json' ?
                html`<textarea 
                @input="${e => {
                        try {
                            let json = JSON.parse(e.target.value)
                            this.updateValue(null, json, currentSchema)
                        } catch (err) {

                        }
                    }}"
                spellcheck="false"
                autofocus
                rows="20"
                style="width: 100%;resize: none;scrollbar-color: green transparent;scrollbar-width: none;border: 1px solid white;background-color: black;color: white;height: 100%;flex:1;overflow: auto;" 
                >${this.stringValue}</textarea>`
                : this.renderValue(currentJson, currentSchema)}
            </section>
        </div>
        `
    }
    renderKey(value, schema, path = []) {
        let key = path[path.length - 1]
        let title = '??'
        try {
            title = JSON.stringify(schema, null, 2)
        } catch (err) { }

        return html`<span class="key" title="${title}" @click="${e => { this.setRoot(path) }}">${key}</span>`
    }
    getSchema(keyOrPath) {
        let schemas = [this.sch()]
        let currentData = this.value
        const path = Array.isArray(keyOrPath) ? keyOrPath : [keyOrPath]
        for (let part of path) {
            currentData = currentData[part]
            let newGeneration = []
            for (let parent of schemas) {
                let children = traverseSchema(parent, part, this.sch())
                for (let accepted of (children || []).filter(s => this.validate(s, currentData)))
                    newGeneration.push(accepted)
            }
            schemas = newGeneration
        }
        return schemas[0]
    }
    renderHead() {
        const renderHeadPart = (label, target) => html`<span><a @click=${e => { this.path = target }}>${label}</a>〉</span>`
        return html`<header style="align-items:center" class="row">
    <div style="flex:1" class="path ">
        ${renderHeadPart("root", [])}
        ${this.path.map((part, idx) => renderHeadPart(part, this.path.slice(0, idx + 1)))}
    </div>
    <div class="btn" >V</div>
    <div class="btn" @click =${() => this.mode = this.mode ? null : 'json'}>J</div>

</header>`
    }

    updateValue(key, newValue, schema) {
        newValue = castValue(newValue, schema)
        const path = key ? (Array.isArray(key) ? key : [key]) : []
        const fullPath = [...this.path, ...path]
        this.lastUpdate = fullPath.join('/')
        if (this.set) {
            this.set(fullPath, newValue)
        } else {
            this.value = set(this.value, fullPath, newValue)
            const evt = new CustomEvent('update', { detail: this.value })
            this.dispatchEvent(evt)
        }
    }
    validate(subschema = {}, value) {
        if (!this.schema)
            return true
        return ajv.validate(subschema, value)
    }
    setRoot(localPath) {
        if (!Array.isArray(localPath)) localPath = [localPath]
        this.path = [...this.path, ...localPath]
    }
    renderValue(value, schema, path = []) {
        if (this.loading) return html`<div>loading</div>`
        if (!Array.isArray(schema)) schema = [schema]
        schema = schema.filter(s => this.validate(s, value))
        schema = schema[0]
        let classes = `value lvl-${path.length}`
        if (!schema)
            schema = infereSchema(value, path)
        const noneditable = this.sch().noneditable || schema.noneditable || path[0] === 'id'
        let { input } = schema
        if (input) {
            if (typeof input == 'string')
                input = { type: input }
            let renderer = customRenderers[input.type]
            if (renderer) {
                return html`<div class="${classes}">${renderer(value, schema, v => this.updateValue(path, v, { ...schema, input }))}</div>`
            } else {
                console.warn('renderer', input.type, 'not registered')
            }
        }



        if (value === null) return html`<span class=${classes}>...</span>`
        let { type } = schema
        if (!type && Array.isArray(value))
            type = 'array'
        if (!type)
            type = 'object'

        let eltId = [...this.path, ...path].join('/')
        switch (type) {
            case "array":
                if (path.length > this.depth) return html`<span class=${classes}>...</span>`
                return this.renderArray(value, schema, path, classes)
            case "string":
                return noneditable ? value : html`<div class="${classes}"><input id="${eltId}" value="${value}" type="text"
        @input="${e => { e.stopPropagation(); this.updateValue(path, e.target.value, schema) }}"></div>`
            case "number":
                return noneditable ? value : renderNumber(value, schema, { update: v => this.updateValue(path, v, schema), classes, id: eltId })
            case "object":
                if (path.length > this.depth) return html`<span class=${classes}>...</span>`
                return this.renderObject(value, schema, path, classes)
            default:
                return html`
    <div class="${classes}">
        <pre>${JSON.stringify(value, null, 2)}</pre>
    </div>`
        }

    }
    renderArray(value, schema, path, classes) {
        return html`<div class="column array ${classes}">
    ${value.map((v, index) => html`<div class="row entry" style="align-items: center;"><span class="key"
            @click="${e => this.setRoot([...path, index])}" style="padding: 0 10px;">-</span>
        <span class="value">${this.renderValue(v, traverseSchema(schema, index), [...path, index],
            this.sch())}</span><span class="del btn">x</span></div>`)}
    <div class="row"><span class="add btn">+</span></div>
</div>`
    }
    renderObject(value, schema, path, classes) {
        return html`
        <div class="column ${classes} object">${Object.entries(value).map(
            ([key, localValue]) => {
                let localSchema = traverseSchema(schema, key, this.sch())
                let localPath = [...path, key]
                if (Array.isArray(localSchema)) {
                    localSchema = localSchema.filter(s => this.validate(s, localValue))
                    localSchema = localSchema[0]
                }
                return html`
            <div class="entry row" @click="">
                ${this.renderKey(localValue, localSchema, localPath)}
                ${this.renderValue(localValue, localSchema, localPath)}
            </div>`
            }
        )}</div>`
    }
    static get styles() {
        return css`
        :host{
            display:flex;
            flex-direction: column;
        }
        .root{
            flex:1;
        }
        section.main{
            flex:1;
        }


        .lvl-0{
            padding: 5px;
        }
        .lvl-0 > .entry > .key{
            min-width: 50px;
        }
        .lvl-0 > .entry:not(:first-child) {
            border-top: 1px solid black;
        }

        .lvl-1 > .entry:not(:first-child) {
            border-top: 1px solid gray;
        }

        .lvl-0 > .entry{
            padding: 15px 0;
        }
        .lvl-1 > .entry{
            padding: 10px 0;
        }
        .lvl-2 > .entry{
            padding: 5px 0;
        }

        .lvl-1 > .entry > .key{
            min-width: 50px;
        }
        .lvl-2 > .entry > .key{
            min-width: 50px;
        }
        input{
            min-width: 100px;
            background-color: transparent;
            color: inherit;
        }
        .lvl-0 > input{
            padding: 10px;
            font-size: 1.5rem;
            width: 100%;
        }
        .lvl-1 > input{
            width: 25%;
        }
        .lvl-2 > input{
            width: 25%;
        }
        .lvl-3 > input{
            width: 25%;
        }
        
        *{
            box-sizing: border-box;
        }
        .root{
            overflow: hidden;
            max-height:100%;
        }
        section{
            overflow: auto;
        }
        .column,.row{
            display: flex;
            align-items: stretch;
            justify-content: stretch;
        }
        .column{
            flex-direction: column;
        }

        .row{
            flex-direction: row;
        }
        .row.entry{
            align-items: center;
            justify-content: flex-start;

        }
        .entry input{
            margin: 0px 10px;
            display: inline-block;
            border: none;
            border-bottom: 1px solid #ccc;
        }
        .key{
            margin-right: 15px;
            min-width:50px;
            width:25%;
        }
        .array > .entry > .key{
            width:unset;
        }

        .value{
            flex:1;
        }
        span.value,span.key{
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        pre{
            margin: 0;
        }

        a{
            text-decoration: underline;
            margin-right: 3px;
            color: inherit;
            cursor: pointer;
        }
        header > *{
            padding: 5px 10px ;
        }


        .btn{
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            background-color: var(--editable-bg-color);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .btn:hover{
            background-color: darkgrey;
            color: black;
        }

        `
    }
}

const NAME = 'edit-object'
if (!customElements.get(NAME))
    customElements.define(NAME, EditObject)