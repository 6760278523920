type NotifLevel = 'error' | 'warning' | 'info' | 'success' | 'debug'

export type Notif = {
    timestamp: number,
    id: string,
    level: NotifLevel,
    theme: string[],
    message: string,
    detail?: any,
    group?: string,
    action?: (e: any, n: Notif) => Promise<void>,
    duration?: number

}

let nextNotifId = 1;
export function createNotif(args: any): Notif {
    if (typeof args === 'string')
        args = { message: arguments[0], detail: arguments[1] }
    let {
        level,
        theme,
        message,
        detail,
        group,
        action,
        id,
        timestamp,
    } = args

    if (!theme)
        theme = []
    if (theme) {
        if (typeof theme === 'string')
            theme = [theme]
    }
    level = level || 'info'
    timestamp = timestamp || Date.now()
    id = id || (nextNotifId++).toString()
    message = message || ''

    return {
        ...args,
        level,
        message,
        theme,
        detail,
        group,
        action,
        timestamp,
        id
    }
}
export function stringifyNotif(n: Notif) {
    const detailString = n.detail ? JSON.stringify(n.detail, null, 2) : 0
    return `${new Date(n.timestamp).toISOString().padEnd(15)} : ${n.theme.join(".").padEnd(15)} ${n.message} ${detailString ? `\n${detailString}` : ''}`
}