import { get, set } from "object-path-immutable"
import { getAccessToken } from "../google"

const GOOGLE_APIS_ENDPOINT = 'https://www.googleapis.com'
const STORAGE_API_ENDPOINT = "https://www.googleapis.com/drive/v3/"



class CloudAgent {
    constructor() {
        this.endpoint = ''
        this.alterations = []
        this.alterations.push({
            filter: (url, init) => url.includes('https://drive.google.com'),
            alter: async (url, init) => {
                const token = await getAccessToken()
                init = set(init, ['headers', 'Authorization'], `Bearer ${token}`)
                let u = new URL(url)

                const fileId = u.pathname.split('/').filter(part=>part.length > 4).pop()
                console.log('fileId', fileId)
                url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`
                return [url, init]
            }
        })
        this.alterations.push({
            filter: (url, init) => url.includes(GOOGLE_APIS_ENDPOINT),
            alter: async (url, init) => {
                const token = await getAccessToken()
                init = set(init, ['headers', 'Authorization'], `Bearer ${token}`)
                let u = new URL(url)
                return [url, init]
            }
        })
    }

    async increase(url, init) {
        let defaultInit = {
            referrerPolicy: 'no-referrer',
            // credentials: 'include',
            cache: 'reload',
            redirect: 'follow'
        }

        init = { ...defaultInit, ...init }


        let t0 = Date.now()
        if (!url.includes('://'))
            url = this.endpoint + url

        const { params } = init
        if (params) {
            var first = true;
            for (var key in params) {
                var value = params[key];
                if (first)
                    url += "?"
                else
                    url += "&"
                url += encodeURI(key) + "=" + encodeURI(value);
                first = false
            }
        }



        // init = { ...init, headers: new Headers(init.headers || {}) }
        init = { ...init }
        
        // use default endpoint ?
        for (let { alter } of this.alterations.filter(a => a.filter(...arguments))) {
            const [newUrl, newInit] = await alter(url, init)
            url = newUrl
            init = newInit
        }
        return [url, init,]
    }

    async fetch(url, init = {}) {
        console.log('cloud-agent', url, init)

        const [newUrl, newInit] = await this.increase(url, init)
        const { dryrun } = init
        // delete init.type
        // delete init.params
        // delete init.extract
        // delete init.dryrun
        // if (init.corsProxy)
        //     url = 'https://cors.bridged.cc/' + url
        console.log('->', newUrl, newInit)
        if (dryrun) {
            console.log('would run request to ', newUrl)
            console.log(JSON.stringify(newInit, null, 2))
        } else {
            return fetch(newUrl, newInit)
        }
    }

}

export default new CloudAgent()