import { LitElement, html, css, svg } from 'lit'
import * as d3 from 'd3'
import InteractionManager from './interaction-manager'
import createBoxSelect from 'web-ui-blocks/box-select'
import 'web-ui-blocks/drop-zone'
import { intersectBbox, transformBbox } from './bbox-utils'
import { findIntersectedLinks } from './graph/edges'
import { sanitizeNode } from './graph'

const VIEWPORT = [-200, -200, 400, 400]

function renderGrid(step) {

  //should adapt grid center
  const cx = 0, cy = 0, nx = 2 * NSTEPS, ny = 2 * NSTEPS


  const minx = cx - nx * step, maxx = cx + nx * step, miny = cy - ny * step, maxy = cy + ny * step
  const xs = Array(2 * nx + 1).fill(0).map((_, i) => cx - nx * step + i * step)
  const ys = Array(2 * ny + 1).fill(0).map((_, i) => cy - ny * step + i * step)
  return svg`
  <g class="grid zoomable">
  ${xs.map(x => svg`<line vector-effect="non-scaling-stroke" x1="${x}" x2="${x}" y1="${miny}" y2="${maxy}"></line>`)}
  ${ys.map(y => svg`<line vector-effect="non-scaling-stroke" x1="${minx}" x2="${maxx}" y1="${y}" y2="${y}"></line>`)}
  
  </g>
  <g class="origin translatable">
  <circle cx="0" cy="0" r="2" fill="orange" />
  </g >
  `

}

function sanid(id) {
  return id.replace(/:/, '_')
}

function hasMoved(e1, e2) {
  const x1 = e1.clientX, y1 = e1.clientY, x2 = e2.clientX, y2 = e2.clientY
  const dx = x1 - x2, dy = y1 - y2
  const MOTION = 3
  return dx * dx + dy * dy < MOTION * MOTION
}
const NSTEPS = 10
const UNIT = 25

function normalize(node, index) {
  if (node.visual) return node
  return { ...node, visual: { ...coordinates[index] } }
}

function moveNode(node, { dx, dy }) {
  const x = node.visual.x + dx
  const y = node.visual.y + dy
  return { ...node, visual: { ...node.visual || {}, x, y } }
}


const NODE_RADIUS = 10
class GraphView extends LitElement {
  constructor() {
    super()
    this.transform = { k: 1, x: 0, y: 0 }
    this.selection = []

    this.down = null
    this.svg = null
    this.zoom = null
    this.edition = null
    this.gridstep = UNIT
    this.coordinates = [0, 0]

    this.updateParts = (filter, updater, type) => {
      this[type] = this[type].map(n => filter(n) ? updater(n) : n)
    }
    this.createPart = (id, part, type = 'nodes') => {
      this[type] = [...this[type], part]
    }
    this.updatePart = (id, part, type) => {
      this[type] = this[type].map(n => n.id === id ? part : n)
    }
    this.deletePart = (id, type) => {
      this[type] = this[type].filter(n => n.id === id)
    }
    this.im = new InteractionManager()
  }

  static get properties() {
    return {
      scale: { type: Number },

      nodes: { type: Array, attribute: false },
      links: { type: Array, attribute: false },
      labels: { type: Array, attribute: false },
      transform: { attribute: false },
      edition: { attribute: false },
      selection: { type: Array },

      setSelection: { type: Function },
      createPart: { type: Function },
      updatePart: { type: Function },
      deletePart: { type: Function },
      // layers: { type: Object},
      hover: { type: String, reflect: true },
      gridstep: { type: Number, attribute: false },
      coordinates: { type: Array, attribute: false }

    }
  }
  updated(changed) {

  }
  render() {
    const scale = 1 / this.transform.k
    return html`
      <div tabindex="0" @keydown="${this.handleKeyDown}" class="root">
        <drop-zone @drop="${(e) => this.handleDrop(e)}">
          <svg viewBox="${VIEWPORT.join(' ')}" @contextmenu="${(e) => this.handleMenu(e)}"
            @click="${(e) => this.handleClick(e, null)}" @mousedown="${(e) => this.handleMouseDown(null, e)}"
            @mouseup="${(e) => this.handleMouseUp(null, e)}" @mousemove="${(e) => this.handleMouseMove(null, e)}">
      
            <defs>
              <!-- Définit une pointe de flèche -->
              <marker id="arrow" fill="#ffffffff" viewBox="-10 -4 8 20" refX="${NODE_RADIUS}" refY="0"
                markerWidth="${NODE_RADIUS}" markerHeight="${NODE_RADIUS}" orient="auto-start-reverse">
                <path d="M -10 -4 l 10 4 l -10 4 z" />
              </marker>
      
              <!-- Définit un simple point -->
              <marker id="dot" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="5" markerHeight="5">
                <circle cx="5" cy="5" r="5" fill="red" />
              </marker>
            </defs>
      
      
            ${renderGrid(this.gridstep)}
            ${this.renderLinks()}
            <g class="nodes">
              ${this.renderNodes()}
              ${this.renderNodeLabels()}
            </g>
            ${this.renderEdition()}
      
      
          </svg>
          <div style="color: white;font-size: 0.8em;position: absolute;bottom: 10px;left: 10px">
            ${'unit:' + this.gridstep.toPrecision(3)}</div>
        </drop-zone>
      </div>
    `;
  }
  handleDrop(e) {
    const coordinates = this.coords(e)
    let objects = []
    for (let type of e.dataTransfer.types) {
      const data = e.dataTransfer.getData(type)
      if (type === 'application/json') {
        try {
          const object = JSON.parse(data)
          objects.push(object)
        } catch (err) {
        }

      } else {

      }
    }
    for (let file of e.dataTransfer.files) {
      const object = {}
      for (let key of ["name", "size", "type", "lastModified"])
        object[key] = file[key]
      if (!object.id)
        object.id = file.name
      objects.push(object)

    }
    for (let object of objects) {
      const evt = new CustomEvent('drop', { detail: { coordinates, object } })
      this.dispatchEvent(evt)
    }
    e.stopPropagation()
  }
  renderNodeLabels() {
    return svg`<g class="labels zoomable" >${this.labels.map((l, i) => {
      const { x, y, color, label, id, hidden } = l
      return svg`<text
      id="${'label-' + sanid(id,)}"
      transform="translate(${x},${y}) scale(${1 / this.transform.k})"   
      x="${0}" 
      y="${- 1.5 * NODE_RADIUS}" 
      _y="0" 
      _fill="${color}"
      visibility="${hidden ? 'hidden' : ''}"
      >${label}</text>`
    })}</g>`
  }

  renderDetails(node) {
    if (node)
      return svg`<foreignObject x="${node.visual.x - 100}" y="${node.visual.y + 0}" width="200px" height="100px">
    <!--
      Dans le cas d'un SVG intégré dans du HTML, le namespace XHTML peut
      être omis, mais il est obligatoire dans le contexte d'un document SVG
    -->
    <div style="border: 1px solid white;background-color: black;color: white;max-width: 200px;max-height: 200px;overflow: auto;" @wheel="${e => { e.stopPropagation(); }}"> 
    <pre >${JSON.stringify(node, null, 2)}</pre>
    </div>
    <!-- <img style="max-width: 100%;max-height: 100%;" src="https://www.b2m-innovation.com/wp-content/uploads/2018/11/AWS_S3_400x200.png">
    <div xmlns="http://www.w3.org/1999/xhtml">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Sed mollis mollis mi ut ultricies. Nullam magna ipsum,
      porta vel dui convallis, rutrum imperdiet eros. Aliquam
      erat volutpat.
    </div> -->
  </foreignObject>`
  }
  renderNodes() {
    return svg`<g class="nodes zoomable">${this.nodes.map((n, i) => {
      n = sanitizeNode(n)
      let { x, y, color } = n.visual
      const selected = this.selection.indexOf(n.id) >= 0
      return svg`<g transform="translate(${x},${y}) scale(${1 / this.transform.k})">
      
      <circle class="${selected ? 'selected' : ''}" 
      @click="${(e) => this.handleClick(e, n)}" 
      @dblclick=${this.handleDblClick.bind(this, n)}
      @mousedown="${(e) => this.handleMouseDown(n, e)}"
      @mouseup="${(e) => this.handleMouseUp(n, e)}"
      @mousemove="${(e) => this.handleMouseMove(n, e)}"
      @contextmenu="${(e) => this.handleMenu(e, n)}"
      cx="0" 
      cy="0" 
      r="${NODE_RADIUS}" 
      fill="${color}" />
      </g>`
    })}</g>`
  }
  handleDblClick(doc) {
    let documentUrl = doc.url || "https://google.com"
    if (documentUrl) {
      window._child = window.open(documentUrl, `${doc.id}`, "width=200")
    }
  }
  updateLabels() {
    let bboxes = []
    const scale = 1 / this.transform.k
    this.labels = this.nodes.map((n, i) => {
      const l = { ...n.visual, label: n.name || 'id:' + n.id, id: n.id }
      const textElement = this.shadowRoot.querySelector('#label-' + sanid(n.id))
      if (textElement) {
        const bbox = transformBbox(textElement.getBBox(), { x: l.x, y: l.y, scale })
        for (let other of bboxes.filter(b => !b.hidden)) {
          if (intersectBbox(bbox, other)) {
            bbox.hidden = true
            l.hidden = true
            break
          }
        }

        bbox.id = n.id
        bboxes.push(bbox)
      }

      return l
    }).filter(l => l)
  }
  connectedCallback() {
    this.updateLabels()
    super.connectedCallback()
  }
  update() {
    this.updateLabels()
    super.update()
  }

  renderLinks() {
    return svg`<g class="links zoomable">${this.links.map(link => {
      const node1 = this.nodes.filter(n => n.id === link.from)[0]
      const node2 = this.nodes.filter(n => n.id === link.to)[0]
      if (node1 && node2 && node1.visual && node2.visual)
        return svg`<line vector-effect="non-scaling-stroke" x1="${node1.visual.x}" y1="${node1.visual.y}" x2="${node2.visual.x}" y2="${node2.visual.y}"
        marker-end="url(#arrow)"/>`
    })}</g>`
  }
  renderEdition() {
    return svg`<g class="edition zoomable">${this.edition === null ? '' :
      this.edition.type === 'link' ? this.renderLinkEdition(this.edition) :
        ''
      }<g>`
  }

  renderLinkEdition({ from, to }) {
    if (from && to) {
      let x1, y1, x2, y2
      {
        let { x, y } = this.coords(from.event)
        x1 = x, y1 = y
      }
      {
        let { x, y } = this.coords(to.event)
        x2 = x, y2 = y
      }
      return svg`<line vector-effect="non-scaling-stroke" class="link" x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}"/>`
    }
  }

  handleKeyDown(evt) {
    // coordinates, target
    evt.context = { origin: 'graph-view' }
  }
  handleMouseMove(n, evt) {
    evt.stopPropagation()
    const coords = this.coords(evt)
    this.coordinates = [coords.x, coords.y]

    if (n)
      this.hover = n.id
    else
      this.hover = null
    if (!this.down) return
    const { node, event, modifier } = this.down

    switch (modifier) {
      case 'shift':
        this.edition = {
          type: 'link',
          from: this.down,
          to: { node: n, event: evt }
        }
        break
      case 'alt':
      case 'ctrl':
        if (!this.edition) {
          this.edition = {
            type: 'box-select',
            from: this.down,
          }
          createBoxSelect(evt, {}, (box) => {
            const p0 = this.coords({ clientX: box.left, clientY: box.top })
            const p1 = this.coords({ clientX: box.left + box.width, clientY: box.top + box.height })
            const inBoxNodes = this.nodes.filter((n) => {
              const { x, y } = n.visual
              if (
                x >= p0.x && x <= p1.x &&
                y >= p0.y && y <= p1.y
              )
                return true
            })
            this.select(inBoxNodes.map(n => n.id), { add: !evt.altKey, remove: evt.altKey })
          })
        }
        this.edition = { ...this.edition, to: { node: n, event: evt } }
        break
      default:
        if (node) {
          let dx, dy
          {
            const coords = this.coords(evt)
            const curNode = this.nodes.filter(a => a.id === node.id)[0]
            dx = coords.x - curNode.visual.x
            dy = coords.y - curNode.visual.y
            this.updatePart(node.id, moveNode(curNode, { dx, dy }), 'nodes')
          }

          for (let id of this.selection) {
            if (id === node.id) continue
            const curNode = this.nodes.filter(n => n.id === id)[0]
            if (curNode)
              this.updatePart(id, moveNode(curNode, { dx, dy }), 'nodes')
          }
        }
        break
    }
  }
  handleMouseDown(node, event) {
    if (event.buttons !== 2)
      this.down = { node, event, modifier: event.shiftKey ? 'shift' : event.ctrlKey ? 'ctrl' : event.altKey ? 'alt' : null }
    if (node) {
      event.preventDefault()
      event.stopPropagation()
    }
  }
  handleMouseUp(node, evt) {

    if (this.edition) {
      switch (this.edition.type) {
        case 'link':
          const { from, to } = this.edition
          if (from.node && to.node) {
            let fromId = from.node.id, toId = to.node.id
            let exists = false
            for (let link of this.links)
              if (link.from === fromId && link.to === toId)
                exists = true
            if (!exists) {
              this.createPart(null, { from: fromId, to: toId }, 'links')
            }
          } else {
            const deletedLinks = findIntersectedLinks(this.coords(from.event), this.coords(to.event), this)
            for (let l of deletedLinks) {
              this.deletePart(l.id, 'links')
            }
          }

          break
        default:
          break
      }
    }



    evt.stopPropagation()
    evt.preventDefault()
    setTimeout(() => {
      this.down = null
      this.edition = null
    })

  }
  handleClick(evt, target) {
    const node = target
    const { id } = node || {}

    if (!this.down || hasMoved(this.down.event, evt)) {
      this.select([id], { reset: !(evt.ctrlKey || evt.altKey), remove: evt.altKey })
    }
    evt.stopPropagation()
    evt.preventDefault()
  }
  select(items, { reset, remove } = { add: true }) {

    let newSelection = reset ? [] : this.selection
    for (let item of items) {
      if (!item) continue
      const index = newSelection.indexOf(item)
      if (remove && index >= 0) {
        newSelection = newSelection.filter(i => i !== item)
      } else if (index < 0) {
        newSelection = [...newSelection, item]
      }
    }
    if (this.selection !== newSelection) {
      // should be debounced
      if (this.setSelection) {
        this.setSelection(newSelection)
      } else {
        this.selection = newSelection

      }
    }


  }
  handleMenu(evt, target) {
    // evt.preventDefault()
    evt.preventDefault()
    let context = evt.context || {}
    if (context.coordinates === undefined)
      context.coordinates = this.coords(evt) // if target : target x,y
    if (context.target === undefined)
      context.target = target // if target : target x,y

    evt.context = context
    // evt.stopPropagation()
    // this.dispatchEvent(evt)
  }
  snapCoords({ x, y }, unit = UNIT) {

    return {
      x: Math.round(x / unit) * unit,
      y: Math.round(y / unit) * unit,
    }
  }
  coords(evt, snapped = false) {
    const pt = this.svg.createSVGPoint();
    pt.x = evt.clientX;
    pt.y = evt.clientY;
    const pt1 = pt.matrixTransform(this.svg.getScreenCTM().inverse());

    let { x, y } = pt1;

    x = (x - this.transform.x) / this.transform.k;
    y = (y - this.transform.y) / this.transform.k;
    if (snapped) {
      const snapped = this.snapCoords({ x, y })
      x = snapped.x
      y = snapped.y
    }
    return { x, y }
  }
  firstUpdated() {
    this.nodes = this.nodes.map((n, i) => normalize(n, i))
    const MAX_ZOOM = 20
    const zoom = d3.zoom()
      .filter(() => {
        return d3.event.buttons != 2 && !d3.event.altKey && !d3.event.shiftKey && !d3.event.ctrlKey;
      })
      .translateExtent([[-5000, -5000], [5000, 5000]])
      .scaleExtent([1 / MAX_ZOOM, MAX_ZOOM]);

    this.svg = this.shadowRoot.querySelector('svg')
    this.zoom = zoom;


    zoom.on("zoom", () => {
      if (this.down !== null && this.down.node !== null)
        return
      this.transform = d3.event.transform;
      const { x, y, k } = d3.event.transform
      this.scale = k
      const translate = `translate(${x},${y})`
      {
        let multiplier = 1
        while (k * multiplier < 1) {
          multiplier *= 2
        }
        while (k * multiplier > 2) {
          multiplier /= 2
        }
        this.gridstep = 2 * UNIT * multiplier
      }


      d3.selectAll(this.shadowRoot.querySelectorAll('.zoomable')).attr("transform", d3.event.transform)
      d3.selectAll(this.shadowRoot.querySelectorAll('.translatable')).attr("transform", translate)
      // this.adaptTexts(d3.event.transform)
    })
    d3.select(this.svg).call(zoom).on("dblclick.zoom", null);

  }
  static get styles() {
    return css`
    :host{
      flex: 1;
    }
    foreignObject{
      overflow: visible;
    }
    .nodes text{
      pointer-events: none;
      fill: white;
      text-anchor: middle
    }
        .root{
            color: yellow;
            
            background-color: black;

            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            position:relative;
        }
        svg .grid line{
          stroke: #333;
          stroke-width: 2;
          pointer-events: none;
        }

        svg {
            height: 100%;
            width: 100%;
        }

        svg circle:hover{
          fill: white;
        }
        svg circle.selected{
          stroke: white;
          stroke-width: 2px;
        }

        .edition line.link{
          stroke-width: 5;
          stroke: white;
          opacity: 0.3;
          pointer-events: none;
        }
        .links line{
          stroke-width: 3;
          stroke: white;
          opacity: 1;
          pointer-events: none;
        }

    `
  }
}

const NAME = 'graph-view'
if (!customElements.get(NAME))
  customElements.define(NAME, GraphView);
