import { LitElement, css, html, unsafeCSS } from "lit-element";

const commonStyle = css`
  :host {
    position: absolute;
    z-index: 50;
  }
  
  .overlay{
    --hidden-pos: -800px;
    --trigger-size: 30px;
    display: flex;
    color: var(--content-color);
  }

  .overlay-trigger {
    background-color: var(--trigger-bg-color);
    border-color: var(--trigger-color) !important;
  }

  .overlay-content {
    background-color: var(--content-bg-color);
  }

  .overlay-content {
    overflow: auto;
    position: absolute;
    display: flex;
  }

  .dark {
    background-color: #000000d0;
    color: white;
  }
  .light {
    background-color: #00000030;
    color: black;
  }

  .overlay-content {
    transition: all 0.3s;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  .start.overlay {
    justify-content: flex-start;
  }
  .middle.overlay {
    justify-content: center;
  }
  .end.overlay {
    justify-content: flex-end;
  }
`;

const columnStyle = css`
  :host {
    height: 100%;
    top: 0;
  }
  .overlay {
    height: 100%;
    flex-direction: column;
  }

  .overlay-trigger {
    height: 100%;
    top: 0;
    width: var(--trigger-size);
  }

  .overlay-content {
    flex-direction: column;
  }

  .start .overlay-content{
    top: 0;
  }
  
  .end .overlay-content{
    bottom: 0;
  }
  

  .full > .overlay-content{
    height: 100%;
  }
`

const rowStyle = css`
  :host {
    width: 100%;
    left: 0;
  }
  .overlay {
    width: 100%;
  }
  .overlay-trigger {
    width: 100%;
    left: 0;
    height: var(--trigger-size);
  }

.overlay-content {
    flex-direction: row;
}

.start .overlay-content{
  left: 0;
}

.end .overlay-content{
  right: 0;
}



.full > .overlay-content{
  width: 100%;
}

`

const leftStyle = css`
  :host {
    left: 0;
  }

  .overlay-content,
  .overlay-trigger {
    left: 0;
  }
  .overlay-trigger {
    border-left: 3px solid;
  }

  .overlay > .overlay-content {
    left: calc(-1 * var(--overlay-size));
  }
  .overlay.visible > .overlay-content {
    left: 0;
  }
  .overlay > .overlay-content {
    left: var( --hidden-pos );
  }
`;
const rightStyle = css`
  :host {
    right: 0;
  }

  .overlay-content,
  .overlay-trigger {
    right: 0;
  }
  .overlay-trigger {
    border-right: 3px solid;
  }



.overlay.visible > .overlay-content {
  right: 0;
}
.overlay > .overlay-content {
  right: var( --hidden-pos );
}
`;
const bottomStyle = css`
  :host {
    bottom: 0;
  }

  .overlay-content,
  .overlay-trigger {
    bottom: 0;
  }



  .overlay.visible > .overlay-content {
    bottom: 0;
  }
  .overlay > .overlay-content {
    bottom: var( --hidden-pos );
  }
`;
const topStyle = css`
  :host {
    top: 0;
  }

  .overlay-content,
  .overlay-trigger {
    top: 0;
  }

  .overlay-trigger {
    border-top: 3px solid;
  }

.overlay.visible > .overlay-content {
  top: 0;
}
.overlay > .overlay-content {
  top: var( --hidden-pos );
}
`;

export {
  bottomStyle,
  topStyle,
  leftStyle,
  rightStyle,
  columnStyle,
  rowStyle,
  commonStyle,
};
