
import './graph-view'
import "web-ui-blocks/overlay/lib"
import createContextMenu from 'web-ui-blocks/contextmenu'
import 'web-ui-blocks/edit-object'
import { computeGraphLayout, resetGenerator, generateId, sanitizeNode, getNode } from './graph'
import { LitElement, html, css, } from 'lit'
import { get, set } from 'object-path-immutable'

import { graphViewRoot } from './widgets'
import graphitistStyles from './style'
import ACTIONS from './actions'

import { renderLegend } from './dataviz'
import widgets from './widgets'
import { renderableGraph } from './graph/render'
import buildGraphSchema from './schema'
import renderLibrary from './library'
import thLargeSolid from './icons/th-large-solid'
import { normalizeLink } from './normalize'
import { getFromClipboard, putInClipboard } from './clipboard'
import { moveGraph } from './graph/geometry'

class Graphitist extends LitElement {
    constructor() {
        super()
        this.nodes = []
        this.links = []
        this.details = {}
        this.renderdetails = a => a
        this.resetState()


        this.allActions = [...ACTIONS].filter(a => a.perform).map(a => ({ ...a, perform: a.perform.bind(this) }))
    }

    static get properties() {
        return {
            nodes: { type: Array },
            links: { type: Array },
            schema: { type: Array },
            library: { type: Array },
            actions: { type: Array },
            state: { type: Object },
            renderdetails: { type: Function },
            trychange: { type: Function },
            details: { type: Object },

        }
    }
    updated(changed) {
        super.updated(changed)
        if (changed.has('nodes') || changed.has('links') || changed.has('schema')) {
            this.resetState()
        }
        if (changed.has('actions')) {
            this.allActions = [...ACTIONS, ...this.actions].filter(a => a.perform).map(a => ({ ...a, perform: a.perform.bind(this) }))
        }
    }
    connectedCallback() {
        super.connectedCallback()
    }

    resetState() {
        resetGenerator()
        const graph = computeGraphLayout(this)
        const { nodes, links } = graph

        this.state = {
            nodes, links,
            selection: [],
            pinned: null,
            expanded: [],
            layers: null,
            root: null,
            schema: buildGraphSchema(this.schema)
        }
        console.log('reset state', graph)
    }
    updateState(props) {
        const oldState = this.state
        const newState = { ...this.state, ...props }
        if (!this.trychange || this.trychange(oldState, newState))
            this.state = newState
    }
    render() {
        const graph = renderableGraph(this.state)
        return html`
        ${this.renderControls()}
        ${this.renderGraph(graph)}
        <div id="botbar">
            <div id="legend">${renderLegend(graph)}</div>${graphViewRoot.render.bind(this)(this.state)}
        </div>
`
    }



    renderGraph(graph) {
        const { nodes, links } = graph
        const { state } = this
        const { layers } = state
        const tmpl = html`
            <graph-view id="graph" @keydown="${this.handleEvent.bind(this)}" @drop="${e => this.handleDrop(e.detail)}"
                @contextmenu="${e => this.handleContextmenu(e)}" .setSelection="${e => this.updateState({ selection: e })}"
                .createPart=${this.createPart.bind(this)} .updatePart=${this.updatePart.bind(this)}
                .deletePart=${this.deletePart.bind(this)} layers=${layers} .nodes=${nodes} .selection="${this.state.selection}"
                .links=${links.map(normalizeLink)}>
            </graph-view>`
        return tmpl
    }
    async handleEvent(e) {
        let handled = false
        switch (e.type) {
            case 'keydown':
                switch (e.key) {
                    case "a":
                        if (e.ctrlKey) {
                            this.state = set(this.state, ['selection'], this.state.nodes.map(n => n.id))
                            handled = true
                        }
                        break
                    case "x":
                        for (let id of this.state.selection) {
                            this.deletePart(id, 'nodes')
                        }
                        handled = true
                        break
                    case "g":
                        if (e.ctrlKey) {
                            this.commit('group-nodes')
                            handled = true
                        }
                        break;
                    case "c":
                        if (e.ctrlKey) {
                            const selection = get(this.state, ['selection'], [])
                            const nodes = selection.map(id => this.state.nodes.filter(n => n.id === id)[0]).filter(n => n)
                            const links = this.state.links.filter(l => {
                                if (nodes.filter(n => n.id === l.from).length
                                    && nodes.filter(n => n.id === l.to).length)
                                    return true
                            })
                            await putInClipboard({ nodes, links })
                            const inClip = await getFromClipboard()
                        }
                        break;
                    case "v":
                        if (e.ctrlKey) {
                            const graphView = this.shadowRoot.querySelector('graph-view')
                            const subgraph = await getFromClipboard()
                            const [x, y] = graphView.coordinates
                            const movedGraph = moveGraph(subgraph, x, y)
                            const { nodes, links } = movedGraph
                            const idMap = {}
                            for (let node of nodes) {
                                const oldId = node.id
                                const newNode = { ...node, id: null }
                                const newId = this.createPart(null, newNode, 'nodes')
                                idMap[oldId] = newId
                            }
                            for (let link of links) {
                                link = {
                                    ...link,
                                    from: idMap[link.from],
                                    to: idMap[link.to],
                                    id: null
                                }
                                const newLinkId = this.createPart(null, link, 'links')

                            }
                        }
                        break;
                    case "s":
                        if (e.ctrlKey) {
                            this.commit('save-graph')
                            handled = true
                        }
                        break

                }
                break
            default:
                break
        }
        if (handled) {
            e.preventDefault()
            e.stopPropagation()
        }
    }
    renderControls() {
        const { state } = this
        const { nodes, links, selection, layers, pinned } = state
        const focus = selection.length === 1 ? nodes.filter(n => n.id === selection[0])[0] : null
        const library = this.library || []
        let schema = {}, localLinks = []
        if (focus) {
            schema = this.schema?.filter(n => n.properties.type.enum[0] === focus.type)[0] || {}
            localLinks = links.filter(l => l.to.split('.')[0] === focus.id)
        }

        let panelOpened = focus || pinned

        const usedIds = this.state.nodes.map(n => n.id)
        const availableLibrary = library.filter(item => (!item.id) || (usedIds.indexOf(item.id) < 0))
        return html`
            <div class="graphitist">
                <right-overlay ?forcevisible="${focus || panelOpened}">
                    <div id="rightbar" class="column" style="justify-content: flex-end;    max-height: 100vh;">
                        ${focus || panelOpened ? html`<slot name="details"></slot>` : ''}
            
                        ${focus ? html`
            
                        ${this.renderdetails(selection, this.details)}
            
                        <div class="column">
                            <h2>Inbound links</h2>
                            ${localLinks.map(l => this.renderLinkEdition(l))}
                        </div>
                        <h2>Node properties</h2>
                        <edit-object style="overflow: auto;height:40%;" .initial=${focus} .schema=${schema} @update=${e => {
                    this.updatePart(e.detail.id, e.detail, 'nodes')
                }}></edit-object>
                        ` : ''}
                    </div>
                </right-overlay>
            
                <top-overlay layout="end" theme="green" style="color:white;" forcevisible>
                    <div id="topbar" class="row" style="padding: 0 50px;">
                        ${widgets.map(widget => html`<div class="widget">${widget.render(state, this.updateState.bind(this),
                    this.commit.bind(this))}</div>`)}
                    </div>
            
                </top-overlay>
                ${availableLibrary.length ?
                html`
                <left-overlay _forcevisible theme="green" style="color:white" layout=full>
                    <div id="leftbar" class="column library" style="width: 300px;background-color:rgba(0,0,0,0.8);flex:1;">
                        ${renderLibrary(availableLibrary)}
                    </div>
            
                </left-overlay>
                ` : ''}
            
                
            </div>
        `
    }

    renderLinkEdition(link) {
        let from = link.from.split('.')[0]
        let to = link.to.split('.')[1]
        let selector = link.from.split('.')[1]
        let endpoint = link.to.split('.')[1]
        return html`<div class="row link">
    <select value=${from}>
        ${this.state.nodes.map(n => html`<option ?selected=${n.id === from} value="${n.id}">${n.id}</option>`)}
    </select>
    <input placeholder="selector..." type="text" value="${selector || ""}" />
    <input placeholder="endpoint..." type="text" value="${endpoint || ""}" />
    <span class="btn" style="margin:0;padding:0 5px;" @click=${e => this.deletePart(link.id, "links")}>x</span>
</div>`
    }

    async commit(actionName, context = {}) {
        const action = this.allActions.filter(a => a.name === actionName)[0]
        if (!action) {
            throw `no action named ${actionName}`
        }
        await action.perform.bind(this)(context)
    }

    createPart(id, part, type) {
        switch (type) {
            case 'nodes':
                // part = sanitizeNode(part)
                id = id || part.id
                break
            default:
                break
        }
        if (!id) id = generateId(type)
        if (this.state[type].filter(n => n.id === id).length)
            throw `${id} already exists among ${type}`
        console.log('create', type, id)
        this.updateState({
            [type]: [...this.state[type], { ...part, id }]
        })
        return id
    }
    updatePart(id, part, type) {
        this.updateState({
            [type]: this.state[type].map(n => n.id === id ? part : n)
        })
    }
    deletePart(id, type) {
        console.log('delete', type, id)
        if (type === 'nodes') {
            const node = getNode(this.state.nodes, id)
            if (!node) return
            const parent = node.parent
            this.updateState({
                [type]: this.state[type].filter(n => n.id !== id).map(n => n.parent === id ? { ...n, parent } : n),
                links: this.state.links.filter(l => l.from !== id && l.to !== id),
                selection: this.state.selection.filter(i => i !== id)
            })
        } else {
            this.updateState({
                [type]: this.state[type].filter(n => n.id !== id)
            })
        }


    }
    handleDrop({ coordinates, object }) {
        this.createPart(null, { ...object, visual: { ...(object.visual || {}), ...coordinates } }, 'nodes')
    }
    handleContextmenu(e) {
        const { context } = e
        const { target } = context
        let nsel = this.state.selection.length
        let title = target ? [target.name + `(${nsel})`] : []
        const xy = [context.coordinates.x, context.coordinates.y].map(n => n.toFixed(2))

        title.push(['x=' + xy[0], 'y=' + xy[1]].join(" ; "))

        createContextMenu(e, context, this.allActions.filter(a => a.text), title.join("<br>"))
    }
    static get styles() {
        return [graphitistStyles]
    }


}
const NAME = 'graph-itist'
if (!customElements.get(NAME))
    customElements.define(NAME, Graphitist)