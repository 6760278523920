import { getNode } from '.'
import { normalizeLink } from '../normalize'
function resolve(id, nodes, rendered, cache = {}) {
    if (!id) return
    if (cache[id]) return cache[id]
    let final = null
    if (rendered.indexOf(id) >= 0) {
        final = id
    } else {
        const node = getNode(nodes, id)
        if (node && node.parent) {
            final = resolve(node.parent, nodes, rendered, cache)
        }
    }
    cache[id] = final
    return final
}

function resolveLink(link, nodes, rendered, cache = {}) {
    return { ...link, from: resolve(link.from, nodes, rendered, cache), to: resolve(link.to, nodes, rendered, cache) }
}

// node coordinates should be relative to parent
export function renderableGraph(graph) {
    let { nodes, links, layers, expanded, root } = graph
    links = links.map(normalizeLink)
    const resolutionCache = {}
    // if (expanded.indexOf(root) < 0)
    //     expanded = [root, ...expanded]
    const filteredExpanded = expanded.filter(id => {
        let depth = 0
        let node = getNode(id)
        while ((node.parent || null) !== root) {
            if (depth >= 10) {
                console.log('too deep or circular parents for ', id)
                return false
            }
            if (expanded.indexOf(node.parent) < 0)
                return false
            node = getNode(node.parent)
            if (!node)
                return false
            depth++
        }
        return true
    })
    const renderedNodes = nodes
        .filter(n => (n.id !== root))
        .filter(n => ((n.parent || null) === root) || (filteredExpanded.indexOf(n.parent) >= 0))
        .filter(n => {
            if (!layers || !n.layers) return true
            for (let l of layers)
                if (n.layers.indexOf(l) >= 0)
                    return true
            return false
        })

    const renderedLinks = links.map(l => resolveLink(l, nodes, renderedNodes.map(n => n.id), resolutionCache)).filter(l => l.from && l.to && l.from !== l.to)

    function warnIgnoredItems(all, used, log) {
        for (let item of all.map(n => n.id)) {
            if (used.map(n => n.id).indexOf(item) < 0)
                log(item)
        }
    }

    const WARNING_IGNORED_ITEMS = true
    if (WARNING_IGNORED_ITEMS) {
        // console._log('nodes', nodes)
        // console._log('rendered nodes', renderedNodes)
        // console._log('links', links)
        // console._log('rendered links', renderedLinks)
        warnIgnoredItems(nodes, renderedNodes, (id) => console.log('Warn: node', id, 'ignored'))
        warnIgnoredItems(links, renderedLinks, (id) => console.log('Warn: link', id, 'ignored'))
    }

    const gr = {
        links: renderedLinks,
        nodes: renderedNodes
    }
    return gr

}