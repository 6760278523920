import { normalizeGraph } from "./graph"

function validate() {
    if (!navigator.clipboard)
        throw `clipboard api is not supported`
}

export async function putInClipboard(data) {
    validate()
    const subgraph = normalizeGraph(data)
    await navigator.clipboard.writeText(JSON.stringify(subgraph))
}

export async function getFromClipboard() {
    validate()
    const txt = await navigator.clipboard.readText()
    const value = JSON.parse(txt)
    const clipboardGraph = normalizeGraph(value)
    return clipboardGraph
}