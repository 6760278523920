function getScroll(elt) {
    // If we're on the server, default to 0,0
    if (typeof document === 'undefined') {
        return {
            x: 0,
            y: 0,
        };
    }
    return {
        x:
            elt.scrollLeft ||
            document.body.scrollLeft ||
            document.documentElement.scrollLeft,
        y:
            elt.scrollTop ||
            document.body.scrollTop ||
            document.documentElement.scrollTop,
    };
}

function computeBox(elt, startPoint, endPoint) {
    if (!startPoint || !endPoint) return
    const clientRect = elt.getBoundingClientRect();
    const scroll = getScroll(elt);
    const left =
        Math.min(startPoint.x, endPoint.x) - clientRect.left - scroll.x;
    const top =
        Math.min(startPoint.y, endPoint.y) - clientRect.top - scroll.y;
    const width = Math.abs(startPoint.x - endPoint.x);
    const height = Math.abs(startPoint.y - endPoint.y);
    return {
        top,
        left,
        width,
        height
    }

}
import './box-select.css'
export default function createBoxSelect(evt, config, handler) {
    const container = document.body
    let startPoint = null,
        endPoint = null,
        modifiers = null
    const elt = document.createElement('div')
    elt.classList.add('box-select')


    function render(box) {
        if (!box) return
        const { left, top, width, height } = box
        const style = {
            left: `${left || 0}px`,
            top: `${top || 0}px`,
            width: `${width || 0}px`,
            height: `${height || 0}px`,
            display: 'block',
        }
        for (let prop in style)
            elt.style[prop] = style[prop]
    }

    function handleMouseDown(e) {
        if (e.button === 2) return
        if (!e.ctrlKey && !e.altKey) return
        e.preventDefault()
        startBox(e)
    }
    handleMouseDown(evt)

    function startBox(e) {
        startPoint = {
            x: e.pageX,
            y: e.pageY
        }
        endPoint = startPoint
        modifiers = {
            alt: e.altKey,
            ctrl: e.ctrlKey
        }
        const box = computeBox(container, startPoint, endPoint) || {}
        render(box)
        container.appendChild(elt)
        window.addEventListener('mousemove', moveBox, true);
        window.addEventListener('mouseup', endBox, true);
    }
    function moveBox(e) {
        endPoint = {
            x: e.pageX,
            y: e.pageY
        }
        const box = computeBox(container, startPoint, endPoint) || {}
        render(box)
    }
    function endBox(e) {

        const box = computeBox(container, startPoint, endPoint) || {}
        if (box.width > 10 && box.height > 10)
            try {
                handler({
                    ...box,
                    x: box.left,
                    y: box.top,
                }, modifiers)

            } catch (err) { console.log('box handler failed', err.toString()) }
        endPoint = null
        startPoint = null
        modifiers = null
        window.removeEventListener('mousemove', moveBox, true);
        window.removeEventListener('mouseup', endBox, true);
        container.removeChild(elt)
    }


}