import { html, svg } from 'lit'

const LEGEND_STEP = 35
export function renderLegend(graph) {
    const prop = "type"
    let continuousValue = false
    if (continuousValue)
        throw 'continous legend not implemented'
    let props = graph.nodes.map(n => n[prop])
    const domain = props.filter((p, i) => props.indexOf(p) === i).filter(p=>p)
    return html`<svg style="opacity: 0.7" id="color-legend" height="${domain.length * LEGEND_STEP + 20}">${domain.map((value, index) => 
    svg`<g transform="scale(1) translate(0, ${LEGEND_STEP * index})"><circle r="10" cx="10" cy="30" fill="${nodeColor(value)}" /><text dominant-baseline="central" fill="white" x="30" y="30">${value}</text></g>`)}</svg>`
}

import * as d3 from 'd3'
const nodeColor = d3.scaleOrdinal(d3.schemeDark2)

export { nodeColor } 