import { html } from "lit-element";

export function renderNumber(value, schema, { update, classes, id }) {
    const { $hint, minimum, maximum, multipleof } = schema
    let type = "number"
    let showValue = false
    if ($hint) {
        const { input } = $hint
        if (input)
            type = input
    }
    if (type === 'range')
        showValue = true


    return html`<div class="${classes}">
    <input class=" ${classes}" min="${minimum || 0}" max="${maximum || 100}" step="${multipleof || 1}" id="${id}" type="${type}" .value=${value.toFixed(3)}
    @input="${e => { e.stopPropagation(); update(e.target.value) }}">
    ${showValue ? value : ''}
    </div>`
}