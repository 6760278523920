import { ConfigPreset } from "./preset"

// url params : url, state(gdrive), preferred, base_url

export type ConfigSourceType = 'gdrive' | 'url'
export type SimpleToolSettings = {
    name: string,
    setup: (config: any, update?: (newConfig: any) => void, source?: string) => Promise<void>,
    builtins: ConfigPreset[],
    normalize: (input: Blob) => any,
    sources: ConfigSourceType[],
    historyLength: number,
    widgetContainer?: string,
    schema?: any,

}
export const defaultToolSettings: SimpleToolSettings = {
    name: 'Ttest',
    async setup(config, update, sourceName) {
        const app = document.querySelector("#app")
        if (app) {
            app.innerHTML = `
    ===
    ${sourceName}
    ===
    
    ${JSON.stringify(config, null, 2)}`
        }
    },
    builtins: [],
    async normalize(input) {// coerce to json
        if (input instanceof Blob) {
            try {
                const text = await input.text()
                const json = JSON.parse(text)
                return json
            } catch (err) {
                throw `failed to parse JSON from response : ${err.toString()}`
            }
        }
        return input
    },
    sources: ['url', "gdrive"],
    historyLength: 5,
    widgetContainer: '#simple-tool-controls'

}