import Ajv from "ajv"
import addFormats from "ajv-formats"


const ajv = new Ajv({ allErrors: true, verbose: true, strictSchema: false })
addFormats(ajv)

export function prettifyErrors(errors) {
    let maxLen = 0
    let step = 10
    let lines = errors.map((e, i) => {
        let instancePath = i === 0 || e.instancePath !== errors[i - 1].instancePath ? e.instancePath : ''.padEnd(e.instancePath.length)
        return { prefix: `${instancePath} ${e.message} (${JSON.stringify(e.params)})`, suffix: e.schemaPath }
    })
    for (let l of lines) {
        maxLen = Math.max(maxLen, l.prefix.length + step)
    }
    maxLen = Math.ceil(maxLen / step) * step
    lines = lines.map(l => l.prefix.padEnd(maxLen, ' ') + l.suffix)
    return lines
}


export async function createValidation(schema) {
    if (typeof schema === 'string') {
        schema = await fetch(schema).then(r => r.json())
    }
    let validate = ajv.compile(schema, {})
    validate.schema = schema
    return validate
}
