export function normalizeLink(link) {
    let fromParts = link.from.split('.')
    let toParts = link.to.split('.')
    return {
        ...link,
        from: fromParts[0],
        to: toParts[0],
        fromModifier: fromParts.slice(1).join('.'),
        toModifier: toParts.slice(1).join('.')
    }
}