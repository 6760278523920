const DEFAULT_NODE_SCHEMA = [{
    type: "object"
}]
export default function buildGraphSchema(nodeSchema = DEFAULT_NODE_SCHEMA) {
    return {
        "$schema": "http://json-schema.org/draft-07/schema",
        "$id": "http://example.com/example.json",
        "type": "object",
        "title": "The root schema",
        "description": "The root schema comprises the entire JSON document.",
        "required": [
            "nodes",
            "links"
        ],
        "properties": {
            "nodes": {
                "$id": "#/properties/nodes",
                "type": "array",
                "title": "The nodes schema",
                "additionalItems": true,
                "items": {
                    "$id": "#/properties/nodes/items",
                    "required": [
                        "id",
                        "type",
                        "config"
                    ],
                    "anyOf": [
                        ...nodeSchema
                    ]
                }
            },
            "links": {
                "type": "array",
                "additionalItems": true,
                "items": {
                    "$id": "#/properties/links/items",
                    "required": [
                        "from",
                        "to"
                    ],
                    "properties": {
                        "from": {
                            "type": "string"
                        },
                        "to": {
                            "type": "string"
                        }
                    },
                    "additionalProperties": true
                }
            }
        },
        "additionalProperties": true
    }
}