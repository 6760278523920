var dagre = require('dagre')

import { nodeColor } from '../dataviz'


const RADIUS = 25
function nodeLayout(node) {
    return { label: node.name || node.id, width: RADIUS, height: RADIUS }
}


import { download } from 'web-ui-blocks/io'
import { get, set } from 'object-path-immutable'
export async function exportGraph(state) {
    const { nodes, links } = state
    const blob = new Blob([JSON.stringify({ nodes, links }, null, 2)], { type: "application/json" })
    download("graph.json", blob)
}

export function getNode(nodes, id) {
    const matches = nodes.filter(n => n.id === id)[0] || nodes.filter(n => n.name && id && (n.name === id))[0]
    return matches
}


export function sanitizeLink(link) {
    const { from, to } = link
    return {
        ...link,
        from: from.toString(),
        to: to.toString()
    }
}

export function sanitizeNode(node) {
    const { visual } = node
    const { x, y, color } = visual || {}
    return {
        ...node,
        id: (node.id || generateId(node.type)).toString(),
        visual: {
            ...visual,
            color: color || nodeColor(node.type || 'default')
        }
    }
}

export function computeGraphLayout({ nodes, links }) {
    console.log('computeGraphLayout')
    const g = new dagre.graphlib.Graph()

    g.setGraph({ rankdir: 'LR' })
    // Default to assigning a new object as a label for each new edge.
    g.setDefaultEdgeLabel(function () { return {}; });
    for (let n of nodes) {
        g.setNode(n.id, nodeLayout(n))
    }
    for (let { from, to } of links) {
        g.setEdge(from.split('.')[0], to.split('.')[0])
    }
    dagre.layout(g)


    const { width, height } = g.graph()
    return {
        nodes: nodes.map(node => {
            const { x, y } = g.node(node.id)
            return {
                ...node,
                visual: {
                    ...(node.visual || {}),
                    x: x - width / 2,
                    y: y - height / 2,
                }
            }
        }),
        links: links.map(l => ({ ...l, id: l.id || generateId() }))
    }
}

let nextId = 1
export function generateId(hint) {
    return (hint ? hint + '-' : '') + (nextId++)
}
export function resetGenerator() {
    nextId = 1
}

export function normalizeGraph(gr, opts = { resetXY: false, resetColors: false }) {
    if (!gr) gr = { nodes: [], links: [] }
    if (Array.isArray(gr)) {
        gr = { nodes: gr, links: [] }
    }
    let nodes = gr.nodes || []
    let links = gr.links || []
    for (let { inputs, id } of nodes) {
        for (let k in inputs) {
            for (let otherid of inputs[k]) {
                links.push({
                    from: otherid,
                    to: id
                })
            }
        }
    }
    if (opts.resetXY) {
        let layout = computeGraphLayout({ nodes, links })
        nodes = layout.nodes
        links = layout.links
    }

    if (opts.resetColors) {
        nodes = nodes.map(n => set(n, ['visual', 'color'], null))
    }

    return { ...gr, nodes: nodes.map(n => sanitizeNode({ ...n, inputs: undefined })), links: links.map(l => sanitizeLink(l)) }
}

export function mergeGraph(g1, g2) {
    g1 = normalizeGraph(g1)
    g2 = normalizeGraph(g2)
    const nodes = [...g1.nodes, ...g2.nodes]
    const links = [...g1.links, ...g2.links]
    return { nodes, links }
}