function intersect1d([x11, x12], [x21, x22]) {
    const diffs = [
        x11 - x21,
        x11 - x22,
        x12 - x21,
        x12 - x22
    ]
    const min = Math.min(...diffs)
    const max = Math.max(...diffs)
    if (min * max < 0) return true
    return false
}

export function transformBbox(bbox, { x = 0, y = 0, scale = 1 }) {
    return {
        x: bbox.x + x,
        y: bbox.y + y,
        width: bbox.width * scale,
        height: bbox.height * scale
    }
}

export function intersectBbox(b1, b2) {
    return intersect1d([b1.x, b1.x + b1.width ], [b2.x, b2.x + b2.width ]) &&
        intersect1d([b1.y, b1.y + b1.height ], [b2.y, b2.y + b2.height])
}