const t0 = Date.now()

import { handle } from 'web-ui-blocks/itc'


import '../../lib'

import { html, render } from 'lit'
import './library.css'
import { normalizeGraph } from "../../lib/graph/index.js"
import { debounce } from "lodash"
import { download } from 'web-ui-blocks/io'
import getInitialState from './initial-state'
import { set } from 'object-path-immutable'
import 'web-ui-blocks/simple-tool/src/style.css'
import createSimpleTool from 'web-ui-blocks/simple-tool/src'
import { init } from 'web-ui-blocks/notifications'
init(document.body, {}, { minLevel: 0 })

let notifyUpdate = () => true

async function setState(state, channel = null) {
    const graph = state
    const { nodes, links, schema, library } = state
    const gr = document.querySelector('graph-itist')
    gr.nodes = graph.nodes
    gr.links = graph.links
    gr.actions = [
        {
            text: 'save graph',
            name: 'save-graph',
            async perform(context) {
                console.log('nothing')
            }
        }
    ]
    if (schema)
        gr.schema = nodeSchema
    if (library)
        gr.library = library


    let currentGraph = graph, flushedGraph = currentGraph
    const flushChanges = () => {
        notifyUpdate(currentGraph)
        const { nodes, links } = currentGraph
        if (nodes !== flushedGraph.nodes || links !== flushedGraph.links) {
            const blob = new Blob([JSON.stringify(currentGraph)], { type: 'application/json' })
            if (channel) {
                channel.send(blob)
            } else {
                // download("graph.json", blob)
            }
            flushedGraph = currentGraph
        }
    }
    const scheduleFlush = debounce(() => flushChanges(), 300, { maxWait: 1000 })
    gr.trychange = (o, n) => {
        currentGraph = n
        scheduleFlush()
        return true
    }

    gr.renderdetails = (selection, details = {}) => {
        if (!selection || !selection.length) return
        const id = selection[0]

        const result = html`
        <!-- <h2>Details about ${id}...</h2> -->
        `
        return result
    }

}


async function launch() {
    /*

    let state = await getInitialState()
    console.log("state")
    console.log(state)

    */
    const useConfig = await createSimpleTool({
        name: 'graphitist',
        async setup(config, update) {
            console.log('graphitist using new config', config)
            await setState(config)
            notifyUpdate = update

        },
        builtins: [],
        sources: ['url', 'raw']
    })


    // try {
    //     const channel = await handle()
    //     channel.addEventListener("message", async (blob) => {
    //         try {
    //             const text = await blob.text()
    //             const json = JSON.parse(text)
    //             const newState = set(state, ['graph'], json)
    //             setState(newState, channel)
    //             setState()
    //         } catch (err) {
    //             console.log('failed to handle itc message', err.toString())
    //         }
    //     })
    //     channel.addEventListener("disconnected", () => console.log("itc disconnected"))
    // } catch (err) {
    //     console.log('no valid itc channel : ', err.toString())
    // }
}
launch().then(() => {
    console.log('done', (Date.now() - t0).toFixed() + 'ms')
})


for (let evt of ['dragover', 'dragenter', 'dragleave', 'drop'])
    document.addEventListener(evt, (e) => console.log(evt, e), true)