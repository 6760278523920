import { render, html } from 'lit'
import { renderSize } from '../../style/prettify'
import { download, fixFilename, upload } from '../../io'
import * as GDrive from '../../google'
// import '../../style/style.css'
import * as icons from '../../style/icons'
export const HISTORY_SOURCE_NAME = 'history'

import { clear, createStore, entries, get, set } from 'idb-keyval'
import { ConfigPreset, getPresetList, remember, wipeHistory } from './preset'
import notify from '../../notifications'
import { retrieveContent } from '../../google/drive'


export default async function mountWidget(current: ConfigPreset, settings, useConfig) {
    const { widgetContainer, } = settings
    const { raw, source, name } = current || {}




    if (widgetContainer) {
        const saveConfig = async () => {
            let dialog = document.querySelector('#main-modal') as any
            if (!dialog) {
                dialog = document.createElement('dialog')
                dialog.id = "main-modal"
                dialog.innerHTML = `
                <form method="dialog">
                  <div class="content">

                  </div>
                  <div class="row">
                    <button class="confirm" value="confirm">Confirm</button>
                    <button class="cancel" value="cancel">Cancel</button>
                  </div>
                </form>
              `
                document.body.appendChild(dialog)
            }
            // await new Promise((res) => setTimeout(res, 100))
            dialog.querySelector('.content').innerHTML = `
            <h2>Gimme your name</h2>
            <input type="text" name="name" id="" placeholder="Enter a name ..." />
            `

            const form = dialog.querySelector('form')
            form.addEventListener('change', (a) => console.log('form changed', a))
            let { name } = current

            let resolve, reject
            const cancelHandler = () => {
                console.log('dialog cancelled')
                reject('dialog cancelled')
            }
            const closeHandler = () => {
                const r = dialog.returnValue
                let output = {}
                for (let name in form.elements) {
                    const value = form.elements[name].value
                    if (!Number.isFinite(Number(name)) && value !== undefined)
                        output[name] = value
                }
                console.log('dialog closed', r, form)
                if (r === 'confirm')
                    resolve(output)
                else if (r === 'cancel' || true)
                    reject('cancelled by user')

            }
            try {
                let result: any = await new Promise((res, rej) => {
                    resolve = res
                    reject = rej
                    dialog.showModal()
                    dialog.addEventListener('cancel', cancelHandler)
                    dialog.addEventListener('close', closeHandler)
                })
                console.log('result : ', result)

                name = (result.name || name).toString()
                let saved = {
                    ...(current || {}),
                    name
                }
                await useConfig(await remember(saved))

            } catch (err) {
                form.reset()
                dialog.removeEventListener('cancel', cancelHandler)
                dialog.removeEventListener('close', closeHandler)
            }

        }
        const importConfig = async () => {
            const files = await upload()
            const raw = files[0]
            const preset = {
                raw,
                name: raw.name,
                source: 'file'
            }

            await useConfig(preset)
        }
        const exportConfig = async () => {
            await download(fixFilename(current.name, current.raw), raw)
        }

        let additionalWidgets = []
        if (settings.sources.includes('gdrive')) {
            try {
                const widget = GDrive.getWidget(async (file) => {
                    const fileContent = await retrieveContent(file.id)
                    await useConfig({ raw: fileContent, source: 'gdrive', name: file.name }, settings, useConfig)
                })
                additionalWidgets.push(widget)
            } catch (err) {
                notify({ message: err.toString(), theme: ['ST', 'ERROR'], duration: 0, level: 'error' })
            }
        }
        const presets = await getPresetList(current, settings)
        const template = html`
        <div class="row">
        
        <select name="" value="${(presets[0] || {}).id}"  @change=${e => {
                const value = e.target.value
                const preset = presets.find(p => p.id === value)
                if (preset)
                    useConfig(preset)
            }
            }>
        ${presets.map((p, i) =>
                html`<option key=${p.id} value="${p.id}">[${p.source}] - ${p.name} - ${renderSize(p.raw.size)}</option>)`
            )}
        </select> 
        ${''// save and copy url can not always work
            // <button title="copy the url">${icons.copy}</button> 
            }

        <button title="import a new config" @click=${importConfig}>${icons.importFile}</button> 
        <button .disabled=${!raw && (current?.current === current?.original)} title="save the current config" @click=${saveConfig}>${icons.save}</button> 
        <button  title="export the current config" @click=${exportConfig}>${icons.exportFile}</button> 
        <button .disabled=${!presets.filter(p => p.source === 'history').length} title="clean history" @click=${() => {
                wipeHistory()
                useConfig(null)
            }}>${icons.clean}</button>         
        ${additionalWidgets}
        </div>
        `

        const container = document.querySelector(widgetContainer)
        console.log('mounting widget', presets,)
        render(template, container)

    }


}