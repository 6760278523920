import { set } from "object-path-immutable"
import graphs from "./builtin-samples/graphs"
import libraries from "./builtin-samples/libraries"
import presets from "./builtin-samples/presets"

async function resolveRef(ref, builtin = {}, schema = null) {
    let resolved
    switch (typeof ref) {
        case 'object':
            resolved = ref
            break
        case 'string':
            if (!ref.includes('.'))
                return resolveRef(builtin[ref])
            try {
                const url = new URL(ref)
            } catch (err) { }
            if (url)
                resolved = await fetch(ref).then(r => r.json())
            else
                throw `${ref} is not a valid url`
            break;
        default:
            break;
    }
    if (resolved) {
        if (schema)
            throw 'schema validation of configuration is not implemented'
        return resolved
    }
}

export default async function getInitialState() {

    const url = (new URL(document.location))
    const params = url.searchParams

    let state = await resolveRef(params.get('preset') || 'default', presets)

    state = set(state, ['graph'], await resolveRef(params.get('graph') || state.graph, graphs))
    state = set(state, ['schema'], await resolveRef(params.get('schema') || state.schema, graphs))
    state = set(state, ['library'], await resolveRef(params.get('library') || state.library, libraries))
    return state
}