import { css } from 'lit-element'
export function themeStyle() {
    return css`
            .overlay.dark{
                --content-bg-color: rgba(0,0,0,0.9);
                --content-color: white;
            }
            .overlay.blue{
                --trigger-color: blue;
                --trigger-bg-color: rgba(0,0,255,0.0);
            }
            .overlay.green{
                --trigger-color: green;
                --trigger-bg-color: rgba(0,255,0,0.0);
            }
            .overlay.red{
                --trigger-color: red;
                --trigger-bg-color: rgba(255,0,0,0.0);
            }`

}