import { clear, createStore, entries, set } from "idb-keyval"
import { SimpleToolSettings } from "./settings"

export type ConfigPreset = {
    raw: Blob,
    name: string,
    source: string,
    current?: any,
    original?: any,
    id?: string,
    // + others based on source
}

function isValidPreset(c) {
    return c.raw instanceof Blob && c.name
}
function fixBuiltin(builtin, index) {
    let preset
    if (builtin instanceof Blob) {
        preset = {
            raw: builtin,
        }
    } else if (typeof builtin === 'object' && !isValidPreset(builtin)) {
        preset = {
            raw: new Blob([JSON.stringify(builtin)], { type: 'application/json' }),
            name: builtin.name
        }
    }


    return {
        ...preset,
        name: preset.name || 'builtin-' + index,
        source: 'builtin'
    }
}

// TODO : rendre visible le fait qu'un preset soit dans l'historique
export async function getPresetList(current: ConfigPreset | null, settings: SimpleToolSettings) {
    const history = await getHistory()

    let presets = [
        current,
        ...(history),
        ...settings.builtins.map(fixBuiltin)
    ].filter(Boolean).map(p => ({ ...p, id: getId(p) }))

    presets = presets
        .filter((p, i) => presets.map(p => p.id).indexOf(p.id) === i)
    return presets
}


const store = createStore('history', 'keyval')

async function getHistory(): Promise<ConfigPreset[]> {
    return await (await entries(store)).map(([key, blob]) => {
        let raw: Blob = blob;
        return { raw: blob as Blob, name: key.toString(), source: 'history' }
    })//.sort((a, b) => a.date < b.date ? -1 : 1)
}

export async function remember(item: ConfigPreset) {
    await set(item.name, item.raw, store)
    return { ...item, source: 'history' }
}
export const wipeHistory = async () => {
    await clear(store)
}

const getId = (p: ConfigPreset) => `${p.source}/${p.name}`
