import { get, set } from 'object-path-immutable'

export function computeBarycenter(graph) {
    let count = 0;
    let sumx = 0, sumy = 0;

    for (let node of graph.nodes) {
        const { x, y } = node.visual
        sumx += x, sumy += y, count += 1
    }
    if (count) {
        return {
            x: sumx / count,
            y: sumy / count
        }
    } else {
        return { x: 0, y: 0 }
    }
}
export function moveGraph(graph, barx, bary) {
    const bar = computeBarycenter(graph)
    let newNodes = graph.nodes.map(n => {
        let { visual } = n
        const { x, y } = visual
        visual = set(visual, ['x'], x - bar.x + barx)
        visual = set(visual, ['y'], y - bar.y + bary)
        return {
            ...n,
            visual
        }
    })
    return {
        ...graph,
        nodes: newNodes
    }
}