import './style.css'
import { render, html } from 'lit-html'
import { set } from 'object-path-immutable'
import { createNotif, Notif, stringifyNotif } from './types'
let notifContainer
let refresher
let currentNotifications = []
const REFRESH_PERIOD = 1000;
const MAX_NOTIFS = 5;

let defaultNotification = {
    message: '',
    detail: '',
    theme: ['info'],
    duration: 5000,
    click: (e, id) => {
        // console.log('click on notif', id);
        deleteNotification(id)
    }
}



function numericLevel(level) {
    if (Number.isFinite(level)) return level
    switch (level) {
        case 'debug':
            return 0
        case 'error':
            return 4
        case 'warning':
            return 3
        case 'success':
            return 3
        case 'info':
        default:
            return 2
    }
}

let MIN_LEVEL = 1

export function init(parent, notifDefaults = {}, config: any = {}) {
    const { minLevel } = config || {}
    if (Number.isFinite(minLevel))
        MIN_LEVEL = numericLevel(minLevel)
    if (notifDefaults)
        defaultNotification = { ...defaultNotification, ...notifDefaults }
    notifContainer = document.createElement('div')
    notifContainer.classList.add('notifications-container')
    parent.appendChild(notifContainer)
    refresher = setInterval(() => {
        refreshNotifications()
    }, REFRESH_PERIOD)
}

function addNotification(notif) {
    const fullNotif = { ...defaultNotification, ...notif, }
    currentNotifications.push(fullNotif)
    renderNotifications()
}

function refreshNotifications() {
    let now = Date.now()
    currentNotifications = currentNotifications.filter(n => (!n.duration) || ((n.timestamp + n.duration) > now))
    let n = currentNotifications.length
    if (n > MAX_NOTIFS)
        currentNotifications = currentNotifications.slice(n - 5)
    renderNotifications()
}

function renderNotifications() {
    render(html`<div class="notifications">
    ${currentNotifications.map(n => renderNotification(n))}
</div>
    `, notifContainer)
}


function renderNotification(notif) {

    let classes = notif.level
    if (notif.click)
        classes += " clickable"
    return html`
    <div class="notification ${classes}" title="${notif.hover || ''}"
        @click="${e => notif.click ? notif.click(e, notif.id) : ""}">
        <div class="content">
            <div class="message" title=${notif.detail ? JSON.stringify(notif.detail, null, 2) : ''}>${notif.message}</div>
            ${(notif.detail && typeof notif.detail === 'string' && notif.detail.length < 200) ? html`<div class="details">${notif.detail}</div>` : ''}
        </div>
        ${notif.actions && notif.actions.length ? html`<div class="actions"></div>` : ''}
    </div>`
}

export function deleteNotification(id) {
    currentNotifications = currentNotifications.filter(n => n.id !== id)
    renderNotifications()
}
export default function notify(arg: Partial<Notif> | string) {
    if (!notifContainer)
        init(document.body)
    const notif = createNotif(arg)
    const level = numericLevel(notif.level)
    if (notif && (level >= MIN_LEVEL)) {
        addNotification(notif)
        console.log(stringifyNotif(notif), notif.detail)
        return notif.id
    }
}