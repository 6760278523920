export function getLayers(state){
    const {nodes} = state
    const ls = []
    for(let n of nodes){
        for(let l of n.layers || [])
        if(ls.indexOf(l) < 0)
            ls.push(l)
    }
    return ls
}

export function toggleLayer(state, layer){
    let layers = state.layers || getLayers(state)
    const index = layers.indexOf(layer)
    if(index >= 0){
        layers = layers.filter(l=>l!==layer)
    }else{
        layers = [...layers, layer]
    }
    return {layers}
}