import { html, css, render } from 'lit'
import 'web-ui-blocks/front-searcher'
import { getNextId } from './factory'
// type
// 

function getType(schema) {

}

function generateNode(nodeBasis, event) {
    const id = nodeBasis.id || (nodeBasis.name + `-${getNextId()}`) || (nodeBasis.type + `-${getNextId()}`)
    const node = {
        ...nodeBasis,
        id
    }
    event.dataTransfer.setData("application/json", JSON.stringify(node))
}

function renderDetails(nodeBasis, e) {
    let root = e.target
    while (root && !root.classList.contains('library'))
        root = root.parentNode
    const selector = `.library #details`
    const container = root.querySelector(selector)
    if (!container)
        throw `no element " ${selector}"`;
    if (nodeBasis) {
        render(html`<pre style="white-space: pre-wrap;">${JSON.stringify(nodeBasis, null, 2)}</pre>`, container)
    } else {
        render(html``, container)
    }

}

function renderLibraryItem(nodeBasis) {
    return html`<div>
    <div draggable="true" class="library-item column"
        style="padding: 10px;border-top: 1px solid white;user-select: none;"
        title="${nodeBasis.description}" @dragstart="${e => generateNode(nodeBasis, e)}" @click="${e => {

            renderDetails(nodeBasis, e)
        }}">
        <div clas="row"><h1 style="margin:0;">${nodeBasis.name || nodeBasis.id}</h1></div>
        <div>${nodeBasis.description}</div>
    </div>
</div>`
}
export default function renderLibrary(library) {
    const searchableFields = ['title', 'description', "type", "id"]
    function handleSearch(e) {
        let matches = e.detail
        let cur = e.target
        while (!cur.classList.contains('library')) cur = cur.parentNode
        const libraryItems = cur.querySelector('.library-items')
        if (libraryItems)
            render(html`${matches.map(({ index }) => library[index]).map(schema => renderLibraryItem(schema))}`, libraryItems)
    }
    return html`
    <section class="column">
        <h2 style="text-align: center;margin:10px 0;" @click="${e => { renderDetails(null, e) }}">
            Library
        </h2>
        <front-searcher .rows="${library}" .fields="${searchableFields}" @search=${handleSearch}>
        </front-searcher>
    </section>
    <section class="library-items">
    </section>
    <section id="details" style="width: 100%;overflow: auto;">
    </section>
    `

}