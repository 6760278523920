import { upload, textFromBlob } from 'web-ui-blocks/io'
import { computeGraphLayout, exportGraph, getNode, mergeGraph } from './graph'
function barycenter(nodes) {
    let sumx = nodes.map(n => n.visual.x).reduce((a, b) => a + b, 0)
    let sumy = nodes.map(n => n.visual.y).reduce((a, b) => a + b, 0)
    return {
        x: sumx / nodes.length,
        y: sumy / nodes.length
    }
}


export default [
    {
        text: 'create node',
        name: 'create-node', 
        perform(context){
            console.log(context)
            const {coordinates} = context
            const newNode = {
                visual: {...coordinates}
            }
            const id = this.createPart(null, newNode, 'nodes')

        }
    },
    {
        text: 'group nodes',
        name: 'group-nodes',
        async perform(context) {
            let { coordinates, ...node } = context
            const { nodes, selection } = this.state
            if (!coordinates) coordinates = barycenter(selection.map(id => getNode(nodes, id)))
            const ids = this.state.selection
            const newNode = { visual: { ...coordinates }, ...node }
            const id = this.createPart(null, newNode, 'nodes')
            for (let nid of ids) {
                const n = this.state.nodes.filter(n => n.id === nid)[0]
                this.updatePart(nid, { ...n, parent: id }, 'nodes')
            }
        }
    },
    {
        text: 'open subgraph',
        name: 'set-view-root',
        async perform(context) {
            const { target } = context
            this.updateState({ root: (target || {}).id || null })

        }
    },
    {
        text: 'move here'
    },
    {
        text: 'group nodes'
    },
    {
        text: 'delete nodes'
    },
    {
        text: 'organize nodes'
    },
    {
        text: 'invert selection'
    },
    {
        text: 'move to layer'
    },
    {
        name: 'reset-graph',
        async perform(context) {
            this.resetState()
        }
    },
    {
        name: 'export-graph',
        async perform(context) {
            exportGraph(this.state)
        }
    },

    {
        name: 'fit-graph',
        async perform(context) {
            let graph = computeGraphLayout(this.state)
            graph = { ...graph, nodes: graph.nodes }
            this.updateState({ ...this.state, ...graph })
        }
    },
    {
        name: 'import-graph',
        async perform(context) {
            const files = await upload()
            let graph = null
            for (let file of files) {
                try {
                    const txt = await textFromBlob(file)
                    const json = JSON.parse(txt)
                    graph = mergeGraph(graph, json)
                } catch (err) {
                    console.log('err', err.toString())
                }
            }
            const { nodes, links } = graph
            if (!nodes.length && !links.length)
                throw `graph json must have nodes or links`
            this.nodes = nodes
            this.links = links
            this.resetState()
            console.log('loading graph', graph)
        }
    },
    {
        name: 'import-schema',
        async perform(context) {
            const files = await upload()
            let file = files[0]
            const txt = await textFromBlob(file)
            const schema = JSON.parse(txt)
            this.schema = schema
        }
    },
    {
        name: 'launch',
        async perform(context) {
            console.log('launching computation')
            const { Imengine } = window
            const { nodes, links } = this.state
            const graph = { nodes, links }
            const targetNode = 'render'
            const imengine = new Imengine(graph)
            const result = await imengine.execute(targetNode)
            console.log('result', result)
        }
    }
]