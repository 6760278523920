const emptyPreset = {
    graph: 'empty',
    schema: null,
    library: null
}

export default {
    default: { ...emptyPreset },
    empty: { ...emptyPreset },
    simple: {
        graph: 'simple'
    },
    knowledge: {
        graph: 'simple'
    },
    imengine: {
        graph: 'empty',
        schema: 'imengine',
    }
}