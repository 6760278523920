import "./style.css"


import { LitElement, css, html, unsafeCSS } from 'lit-element'
import { themeStyle } from './theme'
import { commonStyle, bottomStyle, leftStyle, columnStyle, rightStyle, topStyle, rowStyle } from './styles'
const defaultConfig = {
    potition: 'bottom',
    before: 'left',
    after: 'right',
    direction: 'row'
}
function defineOverlayComponent(name, styles, flavor = 'bottom') {
    class Overlay extends LitElement {
        constructor() {
            super()
            this.timeout = null
            this.theme = undefined
            this.visible = false
            this.layout = "start"
            this.forcevisible = false
            window.addEventListener('mouseout', (evt) => {
                // this.handleLeave(evt.relatedTarget)
            })
        }
        static get properties() {
            return {
                visible: { type: Boolean },
                forcevisible: { type: Boolean },
                layout: { type: String }, // start,end,full, (middle ?)
                theme: { type: String },
            }
        }
        attributeChangedCallback(name, o, n) {
            super.attributeChangedCallback(...arguments)
            if (name === 'visible')
                this.requestUpdate()
        }
        render() {
            return html`
            <div class="overlay ${this.computeClasses()}">
                <div @mouseenter="${this.handleEnter.bind(this)}" class="overlay-trigger">
                </div>
                <div class="overlay-content">
                    <slot @mouseout="${e => this.handleLeave.bind(this, e)}"></slot>
                </div>
            
            </div>
            `
        }
        firstUpdated() {
            this.addEventListener('mouseleave', (e) => this.handleLeave(e));
        }

        computeClasses() {
            let cls = ''

            if (this.visible || this.forcevisible) cls += ' visible'
            cls += ' ' + (this.theme || '')
            cls += ' ' + (this.layout || '')
            return cls
        }

        handleEnter(evt) {

            if (this.forcevisible) return

            if (this.timeout) {
                clearTimeout(this.timeout)
                this.timeout = null
            }
            this.visible = true
        }
        scheduleClose() {
            if (this.timeout) return
            this.timeout = setTimeout(() => {
                this.visible = false
            }, 500)
        }
        handleLeave(evt) {
            let related = (evt || {}).related
            if (evt.x < 0 || evt.y < 0) return

            if (this.forcevisible) return
            let n = 0
            while (true && n < 10) {
                if (related === this.shadowRoot.getRootNode() || related === this.shadowRoot.host) {
                    return
                } else if (related === document.body) {
                    this.scheduleClose()
                    return
                } else if (!related) {
                    this.scheduleClose()
                    return
                } else {
                    related = related.parentNode
                    n++
                }
            }
        }
        static get styles() {
            return [commonStyle, themeStyle(this.theme), ...styles]
        }
    }
    if (!customElements.get(name)) {
        customElements.define(name, Overlay)
        console.log('custom element defined', name)
    }

}

defineOverlayComponent('bottom-overlay', [rowStyle, bottomStyle])
defineOverlayComponent('top-overlay', [rowStyle, topStyle])
defineOverlayComponent('left-overlay', [columnStyle, leftStyle])
defineOverlayComponent('right-overlay', [columnStyle, rightStyle])
