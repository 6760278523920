import mime from 'mime-types'
export function isBlob(data) {
    if (data instanceof Blob || data.toString() === "[object Blob]") {
        return data
    } else {
        console.log('should get a blob but got', data)
    }
}

export function fixFilename(hint, blob) {
    hint = hint.split('/').filter(Boolean).pop()
    let [basename, ...others] = hint.split('.')
    let ext = others.pop()
    basename = [basename, ...others].join(".")
    basename = basename.toLowerCase().replaceAll(/ /g, '_')
    if (!ext)
        ext = mime.extension(blob.type) || ""
    if (!ext.startsWith('.'))
        ext = '.' + ext
    return basename + ext

}

export function download(filename, input) {
    let blob = input
    if (typeof input === 'string')
        blob = new Blob([input], { type: 'text/plain' })
    if (!isBlob(blob))
        blob = new Blob([JSON.stringify(input, null, 2)], { type: 'application/json' })

    var element = document.createElement('a');
    console.log("downloading", blob, URL, URL.createObjectURL)
    const url = URL.createObjectURL(blob)
    element.setAttribute('href', url);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function downloadUrl(filename, url) {
    var element = document.createElement('a');
    console.log("downloading", url, URL, URL.createObjectURL)
    element.setAttribute('href', url);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export async function upload(config) {
    const { single } = { ...config };
    let resolve, reject
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej
    })
    var element = document.createElement('input');
    element.setAttribute('type', 'file');

    element.onchange = (e) => {
        const { files } = element
        console.log(files, files.length)
        if (files.length) {
            resolve(files)
        }
        else {
            reject(`no file selected`)
        }
    }

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    return promise
}

export async function textFromBlob(blob) {
    return new Promise((res, rej) => {
        var reader = new FileReader();
        let t0 = Date.now();

        reader.addEventListener("loadend", () => {
            let tf = Date.now();
            console.log("file reading", tf - t0, "ms");
            let str = reader.result;
            res(str)
        });
        reader.addEventListener('progress', console.log)
        reader.addEventListener('error', (err) => {
            rej(err)
        })
        reader.readAsText(blob);
    })
} 